import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';

import '../CodeEditor.css';

const TRIM_INTERVAL = 1000; // Milliseconds between log trims

const P5SketchIFrame: React.FC<{ code: string, runKey: number }> = ({ code, runKey }) => {
  console.log('P5SIF created');
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const outputRef = useRef<HTMLPreElement>(null);
  const [isFollowingTail, setIsFollowingTail] = useState(true);
  const isFollowingTailRef = useRef(true);
  const [maxElements, setMaxElements] = useState(1000);
  const maxElementsRef = useRef(1000);
  const trimIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const showMaxElementInput = false;

  const addLogEntry = (type: string, message: string) => {
    const logElement = document.createElement('div');
    logElement.className = `${type}-log`;
    logElement.textContent = message;
    outputRef.current?.appendChild(logElement);

    if (isFollowingTailRef.current) {
      outputRef.current?.scrollTo(0, outputRef.current.scrollHeight);
    }
  };

  // have limited the logs to 1000 as if you dont that page slows down which is not ideal
  // when you .appendChild you add an element directly into the DOM, this bypasses the react render system which doesnt see this
  // not sure exactly what is slow this direct element creation should be fast at constant speed
  // could be an issue with memory, DOM tree size, reflow and repaint, scrolling, garbage collection
  const trimLogs = () => {
    if (outputRef.current) {
      while (outputRef.current.childElementCount > maxElementsRef.current) {
        outputRef.current.firstChild?.remove();
      }
    }
  };

  const handleMessage = (event: MessageEvent) => {
    if (event.data && event.data.type) {
      let message = event.data.message;
      addLogEntry(event.data.type, message);
    }
  };

  // This is not pretty but seems to work, you need a ref to update a defined function, states would be stale closure
  // but you also need a state to make the checkbox and input box to work, the state change updated the ref
  useEffect(() => {
    isFollowingTailRef.current = isFollowingTail;
  }, [isFollowingTail]);

  useEffect(() => {
    maxElementsRef.current = maxElements;
  }, [maxElements]);

  useEffect(() => {
    // Function to create a Blob URL containing the HTML and user code
    const createBlobURL = (code: string) => {
      const htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <style>
              html, body {
                margin: 0; 
                padding: 0;
                height: 100%
                overflow: auto;
              }

              main {
                display: block;
                height: 100%;
              }
                
              canvas {
                display: block;
              }

              html {
                scrollbar-width: auto;
                scrollbar-color: #444 transparent; /* For Firefox */
              }

              html::-webkit-scrollbar {
                width: 2rem;
                height: 2rem;
              }

              html::-webkit-scrollbar-track {
                background: transparent;
              }

              html::-webkit-scrollbar-thumb {
                background-color: #444; /* Dark grey color */
                border-radius: 6px;
                border: 3px solid transparent;
                background-clip: content-box;
              }

              html::-webkit-scrollbar-thumb:hover {
                background-color: #666; /* Slightly lighter on hover */
              }
            </style>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.4.0/p5.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.10.0/addons/p5.sound.min.js"></script>
          </head>
          <body>
            <script>
              window.onerror = function(message, source, lineno, colno, error) {
                window.parent.postMessage({
                  type: 'error',
                  message: message,
                }, '*');
                return true;
              };

              // Override console methods
              ['log', 'error', 'warn'].forEach(method => {
                const original = console[method];
                console[method] = function(...args) {
                  window.parent.postMessage({
                    type: method,
                    message: args.map(arg => 
                      typeof arg === 'object' ? JSON.stringify(arg) : arg
                    ).join(' ')
                  }, '*');
                };
              });
            </script>
            <script>
              ${code}
            </script>
          </body>
        </html>
      `;
      return URL.createObjectURL(new Blob([htmlContent], { type: 'text/html' }));
    };

    // Clear logs on every run
    clearLogs();

    // Create a new Blob URL with the current code
    const blobURL = createBlobURL(code);

    // Check if iframeRef.current is not null before setting the src
    if (iframeRef.current) {
      iframeRef.current.src = blobURL;
    }

    window.addEventListener('message', handleMessage);

    trimIntervalRef.current = setInterval(trimLogs, TRIM_INTERVAL);

    // Cleanup function to revoke the Blob URL
    return () => {
      URL.revokeObjectURL(blobURL);
      window.removeEventListener('message', handleMessage);
      if (trimIntervalRef.current) {
        clearInterval(trimIntervalRef.current);
      }
    };
  }, [code, runKey]); // Re-run effect when code or runKey changes


  const clearLogs = () => {
    if (outputRef.current) {
      outputRef.current.innerHTML = '';
    }
  };

   
  return (
    <div className="visual-output-container custom-scrollbar">
      <iframe 
        ref={iframeRef}
        title="p5.js Sketch"
        width="100%"
        height="75%"
        style={{border: 'none'}}
        className="custom-scrollbar"
      />
      <div className="output-section">
        <div className="output-text-container custom-scrollbar">
          <pre className="output" ref={outputRef} id="output-direct"></pre>
        </div>
        <div className="output-controls">
          <Button variant="primary" size="sm" onClick={clearLogs}>Clear Logs</Button>
          <Form.Check 
            type="checkbox"
            id="follow-tail-checkbox"
            label="Follow Tail"
            checked={isFollowingTail}
            onChange={(e) => setIsFollowingTail(e.target.checked)}
            className="follow-tail-checkbox"
          />
          {showMaxElementInput && (
            <InputGroup className="max-elements-input" style={{ width: 'auto' }}>
              <InputGroup.Text>Max log entries</InputGroup.Text>
              <Form.Control
                type="number"
                value={maxElements}
                onChange={(e) => setMaxElements(Math.max(1, parseInt(e.target.value) || 1000))}
                style={{ width: '100px' }}
              />
            </InputGroup>
          )}
        </div>
      </div>
    </div>
  );
};

export default P5SketchIFrame;
  
