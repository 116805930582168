import React, { useState, useRef, useEffect, useCallback } from 'react';
import Form from 'react-bootstrap/Form';


import ColorSchemeSelector from './ColorSchemeSelector';
import Logo from './Logo';
import { getInitials } from './utilities';
import { logger } from './Logger';

import './EditorHeader.css';
import './StudentScreen.css';


const images = {
  kitaLogo: { name: "kita-logo", image: `${process.env.PUBLIC_URL}/Images/kita-logo.png` },
};

interface EditorHeaderProps {
  infoMessage: string;
  connectedMessage: string;
  name: string;
  isTeacher: boolean;
  canClearRedis: boolean;
  isStealthMode: boolean;
  showStealthToggle: boolean;
  handleMenuItemClick: (menuItem: string) => void;
  setStealthMode: (isStealthMode: boolean) => void;
}

const EditorHeader: React.FC<EditorHeaderProps> = React.memo(({ 
  infoMessage, 
  connectedMessage, 
  name, 
  isTeacher, 
  canClearRedis,
  isStealthMode,
  showStealthToggle,
  handleMenuItemClick, 
  setStealthMode 
}) => {
  logger.log('Creating EditorHeader');

  // dropdown variables
  const showKitaLogoMenuRef  = useRef<boolean>(false);
  const showUserMenuRef  = useRef<boolean>(false);
  const [updateMenus, setUpdateMenus] = useState<boolean>(false);
  const kitaLogoRef  = useRef<HTMLDivElement>(null);
  const userRef  = useRef<HTMLDivElement>(null);
  const kitaLogoMenuRef  = useRef<HTMLDivElement>(null);
  const userMenuRef  = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const kitaLogoMenuItems = canClearRedis ? ['About', 'Contact', 'Support', 'Clear Redis'] : ['About', 'Contact', 'Support'];
  const userMenuItems = ['Profile', 'Settings', 'Log out'];

  const handleKitaLogoClick = () => {
    showKitaLogoMenuRef.current = !showKitaLogoMenuRef.current;
    showUserMenuRef.current = false;
    setUpdateMenus(!updateMenus);
  };

  const handleUserMenuClick = () => {
    showUserMenuRef.current = !showUserMenuRef.current;
    showKitaLogoMenuRef.current = false;
    setUpdateMenus(!updateMenus);
  };

  const handleOutsideClick = useCallback((event: MouseEvent) => {
    // Check if the click is inside the modal
    if (modalRef.current && modalRef.current.contains(event.target as Node)) {
      return; // Do nothing if the click is inside the modal
    }

    // only click off the menu if you click outside the menu and outside the original icon
    // it has to ignore the icon click as that is seperately handled to toggle the show, so if we false it and then toggle we just reshow it
    if ((kitaLogoMenuRef.current && !kitaLogoMenuRef.current.contains(event.target as Node)) && 
        (kitaLogoRef.current && !kitaLogoRef.current.contains(event.target as Node))) {
    // if (showKitaLogoMenuRef.current) {  // this line will make the dropdown menu disappear when its clicked
      showKitaLogoMenuRef.current = false;
      setUpdateMenus(u => !u);
    }
    if ((userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) &&
        (userRef.current && !userRef.current.contains(event.target as Node))) {
    // if (showUserMenuRef.current) {  // this line will make the dropdown menu disappear when its clicked
      showUserMenuRef.current = false;
      setUpdateMenus(u => !u);
    }
  }, [kitaLogoMenuRef, kitaLogoRef, userMenuRef, userRef]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const handleStealthModeToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStealthMode(event.target.checked);
  };

  return (
    <div className="header">
      <div className="header-content">
        <div className="header-left-part">
          <div ref={kitaLogoRef} onClick={handleKitaLogoClick}>
            <Logo />
          </div>
          {showKitaLogoMenuRef.current && (
            <div className="header-dropdown-content left" ref={kitaLogoMenuRef}>
              {kitaLogoMenuItems.map((item, index) => (
                <div key={index} className="header-dropdown-item" onClick={() => handleMenuItemClick(item)}>{item}</div>
              ))}
            </div>
          )}
          {isTeacher && showStealthToggle && (
            <div className="stealth-mode-switch">
              <Form.Check 
                type="switch"
                id="stealth-mode-switch"
                label="Stealth Mode"
                checked={isStealthMode}
                onChange={handleStealthModeToggle}
              />
            </div>
          )}
        </div>
        {/* <div className="header-margin-right">
          <ColorSchemeSelector />
        </div> */}
        <div className="header-margin-right files-pane-font">
          {infoMessage}
        </div>
        <div className="header-margin-right files-pane-font">
          {connectedMessage}
        </div>
        <div className='files-pane-font'>{isTeacher ? 'Teacher' : 'Student'} - {name}</div>
        <div className={`initials-circle-header margin-left ${isTeacher ? 'teacher' : 'student'}`} onClick={handleUserMenuClick} ref={userRef}>
          {getInitials(name)}
        </div>
        {showUserMenuRef.current && (
          <div className="header-dropdown-content right" ref={userMenuRef}>
            <ColorSchemeSelector modalRef={modalRef} />
            {userMenuItems.map((item, index) => (
              <div key={index} className="header-dropdown-item" onClick={() => handleMenuItemClick(item)}>{item}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

export default EditorHeader;