import { useState, useRef, useEffect } from "react";
import { BsPencilFill, BsTrashFill, BsLockFill, BsEyeSlashFill, BsDoorClosedFill, BsDoorOpenFill } from "react-icons/bs";
import { FaEllipsisH } from "react-icons/fa";
import { logger } from "../Logger";
import { LessonInfo } from "../types";


interface LessonDropdownProps {
    lesson: LessonInfo;
    onEditName: () => void;
    onDelete: (lessonName: string) => void;
    onStateChange: (lessonInfo: LessonInfo ) => void;
  }
  
const LessonDropdown: React.FC<LessonDropdownProps> = ({ lesson, onEditName, onDelete, onStateChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLDivElement>(null);

  const handleRenameClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    logger.log('LessonDropdown calling onEditName, lessonName: ', lesson.name);
    e.stopPropagation();
    onEditName();
    setIsOpen(false);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    logger.log('LessonDropdown calling onDelete, lessonName: ', lesson.name);
    e.stopPropagation();
    onDelete(lesson.name);
    setIsOpen(false);
  };

  const handleStateChangeClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, lessonState: 'open' | 'closed' | 'locked' | 'hidden') => {
    logger.log('LessonDropdown calling onDelete, lessonName: ', lesson.name);
    e.stopPropagation();
    onStateChange({
      ...lesson, 
      state: lessonState
    });
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if ((dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) && 
        (toggleRef.current && !toggleRef.current.contains(event.target as Node))) {
      setIsOpen(false);
    } else {
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && dropdownRef.current && toggleRef.current) {
      const toggleRect = toggleRef.current.getBoundingClientRect();
      dropdownRef.current.style.top = `${toggleRect.bottom}px`;
      dropdownRef.current.style.left = `${toggleRect.right - dropdownRef.current.offsetWidth}px`;
    }
  }, [isOpen]);

  return (
    <div className="lesson-menu">
      <div ref={toggleRef} onClick={(e) => { e.stopPropagation(); setIsOpen(!isOpen); }}>
        <FaEllipsisH />
      </div>
      {isOpen && (
        <div className="lesson-menu-dropdown" ref={dropdownRef}>
          <div className="lesson-menu-item" onClick={handleRenameClick}>
            <BsPencilFill className="lesson-menu-icon" /> Edit
          </div>
          <div className="lesson-menu-item" onClick={handleDeleteClick}>
            <BsTrashFill className="lesson-menu-icon text-danger" /> Delete
          </div>
          <div className="lesson-menu-item" onClick={(e) => { handleStateChangeClick(e, 'locked'); }}>
            <BsLockFill className="lesson-menu-icon" /> Lock
          </div>
          <div className="lesson-menu-item" onClick={(e) => { handleStateChangeClick(e, 'hidden');  }}>
            <BsEyeSlashFill className="lesson-menu-icon" /> Hide
          </div>
          <div className="lesson-menu-item" onClick={(e) => { handleStateChangeClick(e, lesson.state === 'open' ? 'closed' : 'open'); }}>
            {lesson.state === 'open' ? <BsDoorClosedFill className="lesson-menu-icon" /> : <BsDoorOpenFill className="lesson-menu-icon" />}
            {lesson.state === 'open' ? 'Close' : 'Open'}
          </div>
        </div>
      )}
    </div>
  );
};

export default LessonDropdown;