import chroma from 'chroma-js';

import { LessonInfo } from './types';

export const getInitialsOld = (name: string) => {
  const names = name.split(' ');
  const initials = names.map((name) => name.charAt(0)).join('');
  return initials.toUpperCase();
};

export const getInitials = (name: string) => {
  const names = name.trim().split(' ');

  if (names.length === 1) {
    if (names[0].length === 1) {
      return names[0].toUpperCase();
    } else {
      return names[0].charAt(0).toUpperCase() + names[0].charAt(1).toLowerCase();
    }
  } else {
    const initials = names.map((name) => name.charAt(0).toUpperCase());
    return initials.slice(0, 2).join('');
  }
};

export const getLessonString = (lessonsInfo: LessonInfo[], lesson: string) => {
  const lessonNumber = lessonsInfo.find((lessonInfo) => lessonInfo.name === lesson)?.id?? 1;
  return `Lesson ${lessonNumber}: ${lesson}`;
};

export const isTabCode = (tabName: string | null) => {
  if (!tabName) { return false; }
  const extension = tabName.split('.').pop() || '';
  return extension === 'py' || extension === 'js';
}

export const getFileNameFromTabName = (tabName: string | null) => {
  if (!tabName) { return 'error'; }
  const parts = tabName.split('-');
  return parts.length >= 3 ? parts[2] : parts[0];
};

export const getFileTypeFromTabName = (tabName: string | null) => {
  if (!tabName) { return 'error'; }
  const parts = tabName.split('-');
  return parts[0];
};

export const getNameFromTabName = (tabName: string | null) => {
  if (!tabName) { return 'error'; }
  const parts = tabName.split('-');
  return parts.length >= 3 ? parts[1] : parts[0];
};

export function generateShades(background: string, foreground: string, count: number = 10): string[] {
  const scale = chroma.scale([background, foreground]).mode('lab');
  return Array.from({ length: count }, (_, i) => scale(i / (count - 1)).hex());
}