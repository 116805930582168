import { useState } from "react";

import { logger } from "../Logger";



interface FileRenameInputProps {
    initialFileName: string;
    onRenameComplete: (newFileName: string) => void;
    onCancel: () => void;
  }
  
const FileRenameInput: React.FC<FileRenameInputProps> = ({ initialFileName, onRenameComplete, onCancel }) => {
  logger.log('FileRenameInput created');
  const [newFileName, setNewFileName] = useState(initialFileName);

  const handleRenameComplete = () => {
    logger.log('Rename complete, newFileName: ', newFileName);
    onRenameComplete(newFileName);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onCancel();
  };

  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        value={newFileName}
        onChange={(e) => setNewFileName(e.target.value)}
        onBlur={handleRenameComplete}  // autosave when clicking away, do we really want to do this?
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleRenameComplete();
          }
        }}
        autoFocus
      />
      <button className="btn btn-outline-secondary" onClick={handleCancel}>
        Cancel
      </button>
    </div>
  );
};

export default FileRenameInput;