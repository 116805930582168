import React, { useState, useEffect, CSSProperties } from 'react';
import { Button, Form } from 'react-bootstrap';

import { BsBoxArrowUpRight, BsFullscreenExit } from 'react-icons/bs';


// example urls for slides and quiz
// url='https://docs.google.com/presentation/d/1d7YSKQ840cbco59_EYHLvFWqGO_ZPJ2slpPphabZ8h4/embed'
// url='https://kahoot.it/challenge/03467574?challenge-id=a1973cf2-7808-4cc5-a75f-4afdc204c0c5_1721056720285'

type ContentType = 'slides' | 'quiz';

interface ContentViewerProps {
  contentType: ContentType;
  url: string;
  isFullScreen: boolean;
  onFullScreenToggle: () => void;
  isTeacher: boolean;
  onUrlChange: (newUrl: string) => void;
}

const ContentViewer: React.FC<ContentViewerProps> = ({ 
  contentType,
  url, 
  isFullScreen, 
  onFullScreenToggle,
  isTeacher,
  onUrlChange
}) => {
  const [localUrl, setLocalUrl] = useState(url);

  useEffect(() => {
    setLocalUrl(url);
  }, [url]);

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUrl = e.target.value;
    setLocalUrl(newUrl);
    onUrlChange(newUrl);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isFullScreen) {
        onFullScreenToggle();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFullScreen, onFullScreenToggle]);

  const aspectRatio = contentType === 'quiz' ? '75%' : '56.25%';

  const containerStyle: CSSProperties = isFullScreen 
  ? {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      zIndex: 9999,
      display: 'flex',
      flexDirection: 'column',
    }
  : {
      width: '100%',
      height: '0',
      paddingBottom: aspectRatio,
      position: 'relative',
    };

  const iframeStyle: CSSProperties = isFullScreen
  ? { border: 0, flexGrow: 1 }
  : {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 0,
    };

  const buttonStyle: CSSProperties = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 10000,
  };

  return (
    <div>
      {isTeacher && (
        <Form className="mb-2">
          <Form.Control 
            type="text" 
            value={localUrl} 
            onChange={handleUrlChange}
          />
        </Form>
      )}
      <div style={containerStyle}>
        <iframe 
          src={url}
          width="100%"
          height="100%"
          allowFullScreen={true}
          style={iframeStyle}
          title={`Content viewer for ${url}`}
        />
        <Button 
          onClick={onFullScreenToggle} 
          style={buttonStyle}
          variant="primary"
        >
          {isFullScreen ? <BsFullscreenExit /> : <BsBoxArrowUpRight />}
        </Button>
      </div>
    </div>
  );
}

export default ContentViewer;