import { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { FaChevronRight } from "react-icons/fa";

interface FlippingChevronProps {
  isCollapsed: boolean;
}

const FlippingChevron: React.FC<FlippingChevronProps> = ({ isCollapsed }) => (
  <div
    style={{
      display: 'inline-block',
      transition: 'transform 0.3s ease-in-out',
      transform: isCollapsed ? 'scaleX(-1)' : 'scaleX(1)',
    }}
  >
    <FaChevronRight />
  </div>
);

interface RotatingChevronProps {
  isCollapsed: boolean;
}

// this is another option for moving the chevron
const RotatingChevron: React.FC<RotatingChevronProps> = ({ isCollapsed }) => (
  <div
    style={{
      display: 'inline-block',
      transition: 'transform 0.3s ease-in-out',
      transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)',
    }}
  >
    <FaChevronRight />
  </div>
);

interface CollapseButtonProps {
  isCollapsed: boolean;
  onClick: () => void;
  sizes: string[];
  isTransitioning: boolean;
};

const CollapseButton: React.FC<CollapseButtonProps> = ({ isCollapsed, onClick, sizes, isTransitioning }) => {
  const [buttonPosition, setButtonPosition] = useState('0px');
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const updatePosition = () => {
      if (!buttonRef.current) return;

      const buttonWidth = buttonRef.current.offsetWidth;
      const panelWidth = parseFloat(sizes[0]) / 100 * window.innerWidth;
      setButtonPosition(isCollapsed ? '4rem' : `${panelWidth - buttonWidth}px`);
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => window.removeEventListener('resize', updatePosition);
  }, [isCollapsed, sizes]);

  return ReactDOM.createPortal(
    <button
      ref={buttonRef}
      onClick={onClick}
      style={{
        position: 'fixed',
        left: buttonPosition,
        top: '1.4rem',
        zIndex: 9999,
        color: 'var(--foreground-color)',
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        transition: isTransitioning ? 'left 0.3s ease-in-out' : '',
      }}
    >
      <FlippingChevron isCollapsed={isCollapsed} />
    </button>,
    document.body
  );
};

export default CollapseButton;