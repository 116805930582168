import React from 'react';
import { useColorScheme } from './ColorSchemeContext';

const Logo: React.FC = () => {
  const { currentScheme } = useColorScheme();

  const svgContent = `
    <svg version="1.1" id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         width="100%" viewBox="0 0 68 50" enable-background="new 0 0 68 50" xml:space="preserve">
      <path fill="${currentScheme.background}" opacity="1.000000" stroke="none" 
        d="M49.000000,51.000000 C45.638714,51.000000 42.277424,51.000000 38.456802,50.644554 C32.989986,44.876415 27.982502,39.463722 21.674395,32.645157 C26.251322,28.786606 30.830259,24.926359 35.531368,20.963116 C24.659304,16.953726 18.338844,23.011848 12.000000,30.736330 C12.000000,19.937199 12.000000,10.468599 12.000000,1.000000 C30.903944,1.000000 49.807888,1.000000 69.000000,1.000000 C69.000000,10.685660 69.000000,20.374172 68.620628,30.861118 C65.215332,31.307631 62.189415,30.955713 59.841957,30.682701 C57.067108,27.632454 62.472561,22.111828 55.491459,20.940033 C46.012871,19.349031 50.097752,27.036240 49.255398,29.289156 C45.754639,33.210304 40.106144,27.445131 38.935795,34.490910 C37.367153,43.934509 44.976528,39.952274 49.000000,41.359108 C49.000000,44.701244 49.000000,47.850620 49.000000,51.000000 z"/>
      <path fill="${currentScheme.foreground}" opacity="1.000000" stroke="none" 
        d="M11.517044,1.000000 C12.000000,10.468599 12.000000,19.937199 12.000000,30.736330 C18.338844,23.011848 24.659304,16.953726 35.531368,20.963116 C30.830259,24.926359 26.251322,28.786606 21.674395,32.645157 C27.982502,39.463722 32.989986,44.876415 37.998734,50.644554 C33.643101,51.000000 29.286201,51.000000 24.750031,50.721970 C20.324148,46.572815 16.077536,42.701694 12.000000,38.984695 C12.000000,42.766071 12.000000,46.883034 12.000000,51.000000 C8.357226,51.000000 4.714452,51.000000 1.049628,51.000000 C1.049628,34.333294 1.049628,17.666622 1.049628,1.000000 C4.410867,1.000000 7.722477,1.000000 11.517044,1.000000 z"/>
      <path fill="${currentScheme.foreground}" opacity="1.000000" stroke="none" 
        d="M49.454109,51.000000 C49.000000,47.850620 49.000000,44.701244 49.000000,41.359108 C44.976528,39.952274 37.367153,43.934509 38.935795,34.490910 C40.106144,27.445131 45.754639,33.210304 49.255398,29.289156 C50.097752,27.036240 46.012871,19.349031 55.491459,20.940033 C62.472561,22.111828 57.067108,27.632454 59.841957,30.682701 C62.189415,30.955713 65.215332,31.307631 68.620628,31.329775 C69.000000,34.030594 69.000000,37.061188 68.620529,40.216209 C65.222931,40.691265 62.204800,41.041893 59.000000,41.414207 C59.000000,44.709885 59.000000,47.854942 59.000000,51.000000 C55.969406,51.000000 52.938812,51.000000 49.454109,51.000000 z"/>
      <path fill="${currentScheme.background}" opacity="1.000000" stroke="none" 
        d="M59.486565,51.000000 C59.000000,47.854942 59.000000,44.709885 59.000000,41.414207 C62.204800,41.041893 65.222931,40.691265 68.620529,40.670319 C69.000000,44.252457 69.000000,47.504913 69.000000,51.000000 C65.993401,51.000000 62.983265,51.000000 59.486565,51.000000 z"/>
      <path fill="${currentScheme.background}" opacity="1.000000" stroke="none" 
        d="M12.460636,51.000000 C12.000000,46.883034 12.000000,42.766071 12.000000,38.984695 C16.077536,42.701694 20.324148,46.572815 24.285381,50.721970 C20.307091,51.000000 16.614182,51.000000 12.460636,51.000000 z"/>
    </svg>
  `;

  return (
    <div dangerouslySetInnerHTML={{ __html: svgContent }} />
  );
};

export default Logo;