import React from 'react';

import { BsQuestionSquare } from "react-icons/bs";
import { RiSlideshow2Fill } from "react-icons/ri";
import { SiKahoot } from "react-icons/si";

// could this be optimised, 

const images = {
  python: { name: "python-logo", image: `${process.env.PUBLIC_URL}/Images/python-logo-only.png` }, 
  js: { name: "js-logo", image: `${process.env.PUBLIC_URL}/Images/JavaScript-logo.png` },
};

interface getFileIconProps {
  fileName: string;
  isTab: boolean;
}

const getFileIcon: React.FC<getFileIconProps> = React.memo(({ fileName, isTab }) => {
  const extension = fileName.split('.').pop() || '';

  switch (extension) {
    case 'py':
      return <img src={images.python.image} alt={images.python.name} className={isTab ? 'python-logo-tab' : 'python-logo'} />;
    case 'js':
      return <img src={images.js.image} alt={images.js.name} className={isTab ? 'js-logo-tab' : 'js-logo'} />;
    case 'txt':
      return <i className={isTab ? 'fas fa-file-alt file-icon-tab' : 'fas fa-file-alt file-icon'} />;
    case 'slides':
      return <RiSlideshow2Fill className={isTab ? 'slides-icon-tab' : 'slides-icon'} />;
    case 'quiz':
      return <SiKahoot className={isTab ? 'quiz-icon-tab' : 'quiz-icon'} />;
    default:
      return <BsQuestionSquare className={isTab ? 'question-icon-tab' : 'question-icon'} />;
  }
});

export default getFileIcon;