import React, { useState, useRef, useEffect } from "react";
import { BsPencilFill, BsDoorClosedFill, BsDoorOpenFill, BsEyeSlashFill } from "react-icons/bs";
import { FaEllipsisH } from "react-icons/fa";
import { logger } from "./../Logger";
import { Course } from "./../types";

import "./CourseDropdown.css";

interface CourseDropdownProps {
  course: Course;
  onUpdateCourseName: () => void;
  onUpdateCourseDescription: (newDescription: string) => void;
  onUpdateCourseState: (newState: 'open' | 'closed' | 'hidden') => void;
}

const CourseDropdown: React.FC<CourseDropdownProps> = ({ course, onUpdateCourseName, onUpdateCourseDescription, onUpdateCourseState }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLDivElement>(null);

  const handleEditNameClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onUpdateCourseName();
    setIsOpen(false);
  };

  const handleEditDescriptionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const newDescription = prompt("Enter new description:", course.description);
    if (newDescription !== null) {
      onUpdateCourseDescription(newDescription);
    }
    setIsOpen(false);
  };

  const handleStateChangeClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, newState: 'open' | 'closed' | 'hidden') => {
    e.stopPropagation();
    onUpdateCourseState(newState);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if ((dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) && 
        (toggleRef.current && !toggleRef.current.contains(event.target as Node))) {
      setIsOpen(false);
    } else {
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="course-menu">
      <div ref={toggleRef} onClick={(e) => { e.stopPropagation(); setIsOpen(!isOpen); }}>
        <FaEllipsisH />
      </div>
      {isOpen && (
        <div className="course-menu-dropdown" ref={dropdownRef}>
          <div className="course-menu-item" onClick={handleEditNameClick}>
            <BsPencilFill className="course-menu-icon" /> Edit Name
          </div>
          <div className="course-menu-item" onClick={handleEditDescriptionClick}>
            <BsPencilFill className="course-menu-icon" /> Edit Description
          </div>
          <div className="course-menu-item" onClick={(e) => { handleStateChangeClick(e, course.state === 'open' ? 'closed' : 'open'); }}>
            {course.state === 'open' ? <BsDoorClosedFill className="course-menu-icon" /> : <BsDoorOpenFill className="course-menu-icon" />}
            {course.state === 'open' ? 'Close Course' : 'Open Course'}
          </div>
          <div className="course-menu-item" onClick={(e) => { handleStateChangeClick(e, 'hidden'); }}>
            <BsEyeSlashFill className="course-menu-icon" /> Hide Course
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseDropdown;