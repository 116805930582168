
import { BrowserRouter, Route, Routes } from "react-router-dom"
import React from 'react';

import { ColorSchemeProvider } from "./components/ColorSchemeContext";
import { AppProvider } from './AppContext';
  
import StudentScreen from "./components/StudentScreen";
import TeacherScreen from "./components/TeacherScreen";
import TestEditor from "./components/TestEditor";
import CourseSelectionScreen from "./components/CourseSelectionScreen";
import LandingPage from "./components/LandingPage";

import './App.css';





class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }


  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <p>Something went wrong.</p>;
    }

    return this.props.children;
  }

}

function MyFallbackComponent({ error, resetErrorBoundary }: { error: Error, resetErrorBoundary: () => void }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


function App() {
  return (
    <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <AppProvider>
          <ColorSchemeProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/landing-page" element={<LandingPage />} />
                <Route path="/student" element={<CourseSelectionScreen isTeacher={false} />} />
                <Route path="/student/:course" element={<StudentScreen />} />
                <Route path="/teacher406816" element={<CourseSelectionScreen isTeacher={true} />} />
                <Route path="/teacher406816/:course" element={<TeacherScreen />} />
                <Route path="/test-editor" element={<TestEditor />} />
              </Routes>
            </BrowserRouter>
          </ColorSchemeProvider>
        </AppProvider>
    </ErrorBoundary>
  );
}

export default App;
