import { ColorSchemes } from "./types";
import { generateShades } from "./utilities";

const baseSchemes = {
  light: { background: '#FFFFFF', foreground: '#000000'},
  dark: { background: '#0B0B0B', foreground: '#FFFFFF' },
  sepia: { background: '#F1E7D0', foreground: '#704214' },
  forest: { background: '#2C3E50', foreground: '#ECF0F1' },
  ocean: { background: '#34495E', foreground: '#3498DB' },
  sunset: { background: '#E74C3C', foreground: '#F1C40F' },
  emerald: { background: '#2ecc71', foreground: '#ffffff' },
  amethyst: { background: '#9b59b6', foreground: '#ffffff' },
  sunflower: { background: '#f1c40f', foreground: '#2c3e50' },
  alizarin: { background: '#e74c3c', foreground: '#ecf0f1' },
  turquoise: { background: '#1abc9c', foreground: '#ffffff' },
  "midnight Blue": { background: '#2c3e50', foreground: '#ecf0f1' },
  concrete: { background: '#95a5a6', foreground: '#ffffff' },
  orange: { background: '#f39c12', foreground: '#ffffff' },
  pumpkin: { background: '#d35400', foreground: '#ecf0f1' },
  pomegranate: { background: '#c0392b', foreground: '#ffffff' },
  silver: { background: '#bdc3c7', foreground: '#2c3e50' },
  asbestos: { background: '#7f8c8d', foreground: '#ffffff' },
  "wet Asphalt": { background: '#34495e', foreground: '#ecf0f1' },
  greenSea: { background: '#16a085', foreground: '#ffffff' },
  "belize Hole": { background: '#2980b9', foreground: '#ffffff' },
  wisteria: { background: '#8e44ad', foreground: '#ffffff' },
  "forest Camo": { background: '#4B5320', foreground: '#A9A032' },
  "desert Camo": { background: '#D2B48C', foreground: '#8B4513' },
  "neon Buzz": { background: '#FF1493', foreground: '#00FF00' },
  cyberpunk: { background: '#0D0221', foreground: '#FF00FF' },
  rainforest: { background: '#228B22', foreground: '#FFD700' },
  "ocean Depths": { background: '#000080', foreground: '#40E0D0' },
  "autumn Leaves": { background: '#8B4513', foreground: '#FFA500' },
  "cherry Blossom": { background: '#FFB7C5', foreground: '#800080' },
  nebula: { background: '#4B0082', foreground: '#FF69B4' },
  "solar Flare": { background: '#FF4500', foreground: '#FFD700' },
  "bubblegum Pop": { background: '#FF69B4', foreground: '#1E90FF' },
  "dinosaur World": { background: '#228B22', foreground: '#FF6347' },
  "underwater Adventure": { background: '#00CED1', foreground: '#FF8C00' },
  "unicorn Dreams": { background: '#DA70D6', foreground: '#4169E1' },
  "arctic Tundra": { background: '#E0FFFF', foreground: '#4682B4' },
  volcanic: { background: '#8B0000', foreground: '#FFA07A' },
  meadow: { background: '#90EE90', foreground: '#8B008B' },
  "coral Reef": { background: '#00FFFF', foreground: '#FF1493' },
  "northern Lights": { background: '#191970', foreground: '#7CFC00' },
  "desert Sunset": { background: '#FF8C00', foreground: '#8A2BE2' }
};

export const colorSchemes: ColorSchemes = Object.entries(baseSchemes).reduce((acc, [key, scheme]) => {
  acc[key] = {
    ...scheme,
    shades: generateShades(scheme.background, scheme.foreground)
  };
  return acc;
}, {} as ColorSchemes);