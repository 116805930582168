import React, { createContext, useState, useEffect, useContext } from 'react';
import { colorSchemes } from './ColorSchemes';
import { ColorScheme } from './types';
import { generateShades } from './utilities';

interface ColorSchemeContextType {
  currentScheme: ColorScheme;
  currentSchemeName: string;
  changeColorScheme: (schemeName: string) => void;
  setCustomColorScheme: (background: string, foreground: string) => void;
}

export const ColorSchemeContext = createContext<ColorSchemeContextType | undefined>(undefined);

export const useColorScheme = () => {
  const context = useContext(ColorSchemeContext);
  if (context === undefined) {
    throw new Error('useColorScheme must be used within a ColorSchemeProvider');
  }
  return context;
};

export const ColorSchemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentScheme, setCurrentScheme] = useState<ColorScheme>(colorSchemes.light);
  const [currentSchemeName, setCurrentSchemeName] = useState<string>('light');

  const ensureShadesExist = (scheme: ColorScheme): ColorScheme => {
    if (!scheme.shades || scheme.shades.length === 0) {
      return {
        ...scheme,
        shades: generateShades(scheme.background, scheme.foreground)
      };
    }
    return scheme;
  };

  useEffect(() => {
    const savedScheme = localStorage.getItem('colorScheme');
    if (savedScheme) {
      const parsedScheme = ensureShadesExist(JSON.parse(savedScheme) as ColorScheme);
      setCurrentScheme(parsedScheme);
      // Find the scheme name by comparing the saved scheme with known schemes
      const foundSchemeName = Object.entries(colorSchemes).find(
        ([, scheme]) => scheme.background === parsedScheme.background && scheme.foreground === parsedScheme.foreground
      )?.[0];
      setCurrentSchemeName(foundSchemeName || 'custom');
    }
  }, []);

  useEffect(() => {
    const schemeWithShades = ensureShadesExist(currentScheme);
    
    document.documentElement.style.setProperty('--background-color', schemeWithShades.background);
    document.documentElement.style.setProperty('--foreground-color', schemeWithShades.foreground);
    
    schemeWithShades.shades.forEach((shade: string, index: number) => {
      document.documentElement.style.setProperty(`--shade-${index + 1}`, shade);
    });
  }, [currentScheme]);

  const changeColorScheme = (schemeName: string) => {
    if (schemeName in colorSchemes) {
      const newScheme = ensureShadesExist(colorSchemes[schemeName]);
      setCurrentScheme(newScheme);
      setCurrentSchemeName(schemeName);
      localStorage.setItem('colorScheme', JSON.stringify(newScheme));
    }
  };

  const setCustomColorScheme = (background: string, foreground: string) => {
    const customScheme: ColorScheme = ensureShadesExist({ 
      background, 
      foreground, 
      shades: []
    });
    setCurrentScheme(customScheme);
    setCurrentSchemeName('custom');
    localStorage.setItem('colorScheme', JSON.stringify(customScheme));
  };

  return (
    <ColorSchemeContext.Provider value={{ currentScheme, currentSchemeName, changeColorScheme, setCustomColorScheme }}>
      {children}
    </ColorSchemeContext.Provider>
  );
};