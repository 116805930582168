import React, { useState, useEffect, useRef, useReducer, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import debounce from 'lodash/debounce';

import EditorHeader from './EditorHeader';
import CodeEditor from './CodeEditor';
import { useColorScheme } from './ColorSchemeContext';
import { CodeData, initialCodeData, defaultStealthModeUserInfo, UserInfo, LessonInfo } from './types';
import { logger } from './Logger';

import './StudentScreen.css';


let backendURL = process.env.REACT_APP_BACKEND_WS_URL
if (backendURL == null){
    backendURL = "ws://localhost:8000"
}


type Permissions = {
  [lesson: string]: string[];
};

const TeacherScreen: React.FC = () => {
  logger.log('Creating TeacherScreen');
  const [lesson, setLesson] = useState('');
  const [teacherName, setTeacherName] = useState('');
  const [loading, setLoading] = useState(true);
  const codeDataRef = useRef<CodeData>(initialCodeData);
  const { course = '' } = useParams<{ course?: string }>();
  const [courseName, setCourseName] = useState('');
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [permissions, setPermissions] = useState<Permissions>({});
  const [isStealthMode, setIsStealthMode] = useState(false);
  const { changeColorScheme } = useColorScheme();

  const ws = useRef<WebSocket | null>(null); // Initialize the ref
  const [connectedMessage, setConnectedMessage] = useState('(disconnected)');
  const [infoMessage, setInfoMessage] = useState('');
  const reconnectAttempt = useRef<number>(0);
  const websocketStateRef = useRef<'disconnected' | 'connecting' | 'connected'>('disconnected');
  const maxReconnectAttempts = 13;
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [lastPongTimestamp, setLastPongTimestamp] = useState<number | null>(null);
  const pongCheckIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const pingIntMillis = 60000; // 1 minute
  const pongIntMillis = 60000; // 1 minute
  const pongCheckIntervalMillis = 65000; // 1 minute 5 seconds
  const pongCount = useRef<number>(0);
  const pongCountThreshold = 3;
  const navigate = useNavigate();


  

  // To note: this logic is pretty confusing, there are functions calling each other, there are state variables as well as function parameters and local storage variables
  // is confusing what are the values for these, with the ref there is a stale closure issue, but now with callbacks with dependencies will this change things?
  // the useCallbacks are used to remove the warning, but not sure if this is the best way to do it, also do we want the ws reconnection to be triggered so often
  // I think best if the ws connection is not reset unless the course is changed, which is fine as that will only happen with a url change anyway
  // if ws connection is happening on every lesson change then take out the useCallbacks and just disable the warnings manually

  const clearIntervals = () => {
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
    }

    if (pongCheckIntervalRef.current) {
      clearInterval(pongCheckIntervalRef.current);
    }
  };

  // warning suggests to wrap in a useCallback, but cant as needs ref to setupWebSocketHandlers and also not sure how to deal with async
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reconnectWebSocket = async (nameForConnection: string, course: string, lesson: string) => {
    if (websocketStateRef.current === 'connecting') {
      logger.log('StudentScreen: reconnectWebsocket: Already attempting to reconnect, skipping additional attempt');
      return;
    }

    websocketStateRef.current = 'connecting';

    if (reconnectAttempt.current === 0) {
      pongCount.current = 0;
    }
    
    let localAttempt: number = reconnectAttempt.current;

    while (localAttempt < maxReconnectAttempts) {
      const delay = Math.pow(2, localAttempt) * 1000; // Exponential backoff delay
      logger.log(`StudentScreen: reconnectWebsocket: Attempt ${localAttempt + 1} to reconnect in ${delay}ms...`);

      reconnectAttempt.current = localAttempt + 1;

      try {
        await new Promise((resolve) => setTimeout(resolve, delay));
        //ws.current = new WebSocket(backendURL + `/ws/teacher?name=${encodeURIComponent(nameForConnection?? "Error")}`);
        ws.current = new WebSocket(backendURL + `/ws/teacher?name=${encodeURIComponent(nameForConnection?? "Error")}&course=${encodeURIComponent(course?? "Error")}&lesson=${encodeURIComponent(lesson?? "Error")}`);
        setupWebSocketHandlers(nameForConnection, course, lesson);
        setLastPongTimestamp(null);
        logger.log('StudentScreen: reconnectWebsocket: connection successful, state set to connected');
        setConnectedMessage('(connected-preliminary)');
        websocketStateRef.current = 'connected';
        break; // If the connection is successful, break out of the loop
      } catch (error) {
        logger.error('StudentScreen: reconnectWebsocket: error caught during reconnection attempt, state => disconnected, error:', error);
        localAttempt++;
        websocketStateRef.current = 'disconnected';
      }
    }

    if (localAttempt === maxReconnectAttempts) {
      logger.log('StudentScreen: reconnectWebsocket: Maximum reconnect attempts reached. Giving up.');
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkPongTimeout = (nameForConnection: string, course: string, lesson: string) => {
    if (lastPongTimestamp && Date.now() - lastPongTimestamp > pongCheckIntervalMillis) {
      logger.log('Pong timeout, reconnecting...');
      reconnectWebSocket(nameForConnection, course, lesson);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setupWebSocketHandlers = (nameForConnection: string, courseParam: string, lessonParam: string) => {
    if (!ws.current) return;

    ws.current.onopen = () => { 
      logger.log('WebSocket Connected'); 
      setConnectedMessage('');
      setInfoMessage('');

      // Send initial connection message to server
      if (ws.current) {
        logger.log('>>>>>> TeacherScreen sending initial connection message');
        ws.current.send(JSON.stringify({
          type: 'initialConnection',
        }));
      }

      clearIntervals();

      pingIntervalRef.current = setInterval(() => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          logger.log('>>>>>> TeacherScreen, sending ping:', { type: 'ping' });
          ws.current.send(JSON.stringify({ type: 'ping' }));
        }
      }, pingIntMillis); // Send a ping every x milliseconds (usually 1 minute)

      pongCheckIntervalRef.current = setInterval(() => {
        checkPongTimeout(nameForConnection?? 'Error', courseParam, lessonParam);
      }, pongIntMillis); // Check for pong timeout every x milliseconds (usually 1 minute)
    };
  
    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message.type === 'studentTextUpdate') {
        logger.log('<<<<<< TeacherScreeen: Received studentTextUpdate via WS, message: ', message);
        const { studentName, studentFileName, studentFileText, userInfoName, currentTimestamp, lastEditTimes, sentFrom, sentFromTeacher, isStealthMode } = message;
        
        codeDataRef.current = {
          ...codeDataRef.current,
          studentFiles: {
            ...codeDataRef.current.studentFiles,
            [studentName]: {
              ...(codeDataRef.current.studentFiles ? codeDataRef.current.studentFiles[studentName] : {}),
              [studentFileName]: {
                ...(codeDataRef.current.studentFiles ? codeDataRef.current.studentFiles[studentName][studentFileName] : {}),
                text: studentFileText
              }
            }
          },
          userInfo: {
            ...codeDataRef.current.userInfo,
            [sentFrom]: isStealthMode ? defaultStealthModeUserInfo : userInfoName,
          },
          lastEditTimes: lastEditTimes,
          currentTimestampBE: currentTimestamp,
          currentTimestampFE: Date.now(),
          changeType: isStealthMode ? null : (sentFromTeacher ? 'teacherUpdatesStudent' : 'studentUpdatesSelf'),
          changeFile: isStealthMode ? null : studentFileName,
          changeName: isStealthMode ? null : sentFrom,
          version: codeDataRef.current.version + 1 
        };
        forceUpdate();  // force a rerender

      } else if (message.type === 'teacherTextUpdate') {
        logger.log('<<<<<< TeacherScreeen: Received teacher text update from teacher via WS, message: ', message);
        const { fileName, fileText, userInfoName, currentTimestamp, lastEditTimes, sentFrom, sentFromTeacher, isStealthMode } = message;
        codeDataRef.current = {
          ...codeDataRef.current,
          teacherFiles: {
            ...codeDataRef.current.teacherFiles,
            [fileName]: {
              ...codeDataRef.current.teacherFiles ? codeDataRef.current.teacherFiles[fileName] : {},
              text: fileText,
            }
          },
          userInfo: {
            ...codeDataRef.current.userInfo,
            [sentFrom]: isStealthMode ? defaultStealthModeUserInfo : userInfoName,
          },
          lastEditTimes: lastEditTimes,
          currentTimestampBE: currentTimestamp,
          currentTimestampFE: Date.now(),
          changeType: isStealthMode ? null : (sentFromTeacher ? 'liveCoding' : 'studentUpdatesTeacher'),
          changeFile: isStealthMode ? null : fileName,
          changeName: isStealthMode ? null : sentFrom,
          version: codeDataRef.current.version + 1 
        };
        forceUpdate();  // force a rerender

      } else if (message.type === 'batchedStudentUpdate') {
        logger.log('<<<<<< TeacherScreeen: Received batchedStudentUpdate via WS, message: ', message);
        const { studentFiles, userInfos, currentTimestamp, lastEditTimes } = message as {
          studentFiles: { [studentName: string]: { [fileName: string]: string } };
          userInfos: { [studentName: string]: UserInfo };
          currentTimestamp: number;
          lastEditTimes: { [key: string]: number };
        };

        let updatedStudentFiles = { ...codeDataRef.current.studentFiles };
        let updatedUserInfo = { ...codeDataRef.current.userInfo };

        // Update student files
        Object.entries(studentFiles).forEach(([studentName, files]) => {
          if (!updatedStudentFiles[studentName]) {
            updatedStudentFiles[studentName] = {};
          }
          Object.entries(files).forEach(([fileName, fileText]) => {
            updatedStudentFiles[studentName][fileName] = {
              ...updatedStudentFiles[studentName][fileName],
              text: fileText
            };
          });
        });

        // Update user info
        Object.entries(userInfos).forEach(([studentName, userInfo]) => {
          updatedUserInfo[studentName] = userInfo;
        });
        
        codeDataRef.current = {
          ...codeDataRef.current,
          studentFiles: updatedStudentFiles,
          userInfo: updatedUserInfo,
          lastEditTimes: lastEditTimes,
          currentTimestampBE: currentTimestamp,
          currentTimestampFE: Date.now(),
          changeType: 'studentUpdatesSelf',
          changeFile: null,  // what to do about typing
          changeName: null, // what to do about typing
          version: codeDataRef.current.version + 1 
        };
        forceUpdate();  

      } else if (message.type === 'studentStructureUpdate') {
        logger.log('<<<<<< TeacherScreen: Received studentStructureUpdate message: ', message);
        const { studentName, studentFiles, userInfo } = message;
        codeDataRef.current = {
          ...codeDataRef.current,
          studentFiles: {
            ...codeDataRef.current.studentFiles,
            [studentName]: studentFiles
          },
          userInfo: userInfo,
          changeType: 'structureUpdate',
          version: codeDataRef.current.version + 1
        };
        forceUpdate();  // force a rerender

      } else if (message.type === 'teacherStructureUpdate') {
        logger.log('<<<<<< TeacherScreen: Received teacherStructureUpdate message: ', message);
        const { teacherFiles, userInfo } = message;
        codeDataRef.current = {
          ...codeDataRef.current,
          teacherFiles: teacherFiles,
          userInfo: userInfo,
          changeType: 'structureUpdate',
          version: codeDataRef.current.version + 1
        };
        forceUpdate();  // force a rerender

      } else if (message.type === 'teacherCourseUpdate') {
        logger.log('<<<<<< TeacherScreen: Received teacherCourseUpdate message: ', message);
        const { lessonsInfo } = message;
        codeDataRef.current = {
          ...codeDataRef.current,
          lessonsInfo: lessonsInfo,
          changeType: 'structureUpdate',
          version: codeDataRef.current.version + 1
        };
        forceUpdate();  // force a rerender

      } else if (message.type === 'permissionsUpdate') {
        logger.log('<<<<<< TeacherScreen: Received permissionsUpdate message: ', message);
        const { permissions } = message;
        setPermissions(permissions);
        forceUpdate();  // force a rerender

      } else if (message.type === 'initialUpdate') {
        logger.log('<<<<<< TeacherScreeen: Received initial update via WS, message: ', message);
        const { studentFiles, teacherFiles, userInfo, currentTimestamp, lastEditTimes, studentConnections, teacherConnections, allStudents, lessonsInfo, isStealthMode, permissions } = message;
        codeDataRef.current = {
          ...codeDataRef.current,
          teacherFiles: teacherFiles,
          studentFiles: studentFiles,
          userInfo: userInfo,
          changeType: 'initialUpdate',
          lastEditTimes: lastEditTimes,
          currentTimestampBE: currentTimestamp,
          currentTimestampFE: Date.now(),
          studentConnections: studentConnections,
          teacherConnections: teacherConnections,
          allStudents: allStudents,
          lessonsInfo: lessonsInfo,
          version: codeDataRef.current.version + 1
        };
        logger.log('TeacherScreen WS initialUpdate current vars, courseParam: ', courseParam, ', lesson: ', lesson, ', loading: ', loading);
        if (message.lesson !== lesson) {
          logger.log('TeacherScreen WS initialUpdate setting lesson: ', message.lesson);
          localStorage.setItem('currentLesson', message.lesson);
          setLesson(message.lesson);
        }
        logger.log('TeacherScreen WS initialUpdate setting course: ', message.course);
        setCourseName(message.course);
        logger.log('TeacherScreen WS initialUpdate setting isStealthMode: ', isStealthMode);
        setIsStealthMode(isStealthMode);
        logger.log('TeacherScreen WS initialUpdate setting permissions: ', permissions);
        setPermissions(permissions);
        logger.log('TeacherScreen WS initialUpdate setting loading: false');
        setLoading(false);
        
        forceUpdate();  // force a rerender

      } else if (message.type === 'info') {
        logger.log('<<<<<< TeacherScreeen: Info message: ', message);

      } else if (message.type === 'connectionUpdate') {
        logger.log('<<<<<< TeacherScreeen: Received connectionUpdate via WS, message: ', message);
        const { studentConnections, teacherConnections, allStudents } = message;
        codeDataRef.current = {
          ...codeDataRef.current,
          studentConnections: studentConnections,
          teacherConnections: teacherConnections,
          allStudents: allStudents,
          version: codeDataRef.current.version + 1
        };
        forceUpdate();  // force a rerender

      } else if (message.type === 'pong') {
        logger.log('<<<<<< TeacherScreen: Received pong from server, pongCount.current: ', pongCount.current);
        pongCount.current++;
        if (pongCount.current >= pongCountThreshold && reconnectAttempt.current > 0) {
          logger.log(`TeacherScreen: pongCount.current=${pongCount.current}, pongCountThreshold=${pongCountThreshold}, reset reconnection attempts`);
          reconnectAttempt.current = 0;
        }
        setLastPongTimestamp(Date.now());

      } else {
        logger.error('<<<<<< TeacherScreen: Unknown message type received from server: ', message);
      }
    };

    ws.current.onclose = () => { 
      logger.log('TeacherScreen WS onclose, WS disconnected');
      setConnectedMessage('(disconnected-onclose)');
      reconnectWebSocket(nameForConnection?? 'Error', courseParam, lesson);
    };

    ws.current.onerror = (error) => { 
      logger.error('TeacherScreen WS onerror Error: ', error);
      setInfoMessage('WS error:' + error.type);

      if (ws.current?.readyState === WebSocket.CLOSED) {
        setConnectedMessage('(disconnected-onerror)');
        reconnectWebSocket(nameForConnection?? 'Error', courseParam, lesson);
      } else {
        logger.log('WS Error but websocket appears to be open so do not reconnect');
      }
    };
  };

  useEffect(() => {
    const storedName = localStorage.getItem('teacherName');
    const storedLesson = localStorage.getItem('currentLesson');
    logger.log('TeacherScreen: Initial setup, storedName: ', storedName, ', storedLesson: ', storedLesson);
    let teacherName: string | null = storedName;
    let initialPrompt = true;
    
    
    while (!teacherName || teacherName.trim() === "" || teacherName.includes('-')) {
      if (initialPrompt) {
        teacherName = prompt("Please enter your name:");
        initialPrompt = false;
      } else {
        teacherName = prompt('Please enter your name (cannot be empty or contain "-"):');
      }
      if (teacherName && teacherName.trim() !== "" && !teacherName.includes('-')) {
        localStorage.setItem('teacherName', teacherName);
      }
    }
    setTeacherName(teacherName?? "Error");

    codeDataRef.current = {
      ...codeDataRef.current,
      userInfo: {
        ...codeDataRef.current.userInfo,
        [teacherName ?? "Error"]: {
          userType: 'teacher',
          caretPosition: { line: 1, col: 1 },
          highlightedRange: { anchor: { line: 1, col: 1 }, head: { line: 1, col: 1 } },
          activeFile: null,
        }
      },
      version: codeDataRef.current.version + 1
    };
  
    // Connect to WebSocket server
    logger.log('TeacherScreen: teacher ', teacherName, ' initially connecting to WS');
    ws.current = new WebSocket(backendURL + `/ws/teacher?name=${encodeURIComponent(teacherName?? "Error")}&course=${encodeURIComponent(course?? "Error")}&lesson=${encodeURIComponent(storedLesson?? "Error")}`);

    setupWebSocketHandlers(teacherName, course, storedLesson?? 'Error');
    
    return () => {
      logger.log('TeacherScreen unmounting, clean up');
      if (ws.current) {
        logger.log('TeacherScreen unmounting, closing WS');
        ws.current.close();
      } else {
        logger.log('TeacherScreen unmounting, WS not open, so not closing it');
      }

      logger.log('TeacherScreen unmounting, clean up ping and pong intervals');
      clearIntervals();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateFile = useCallback(
    debounce((isTeacherFile: boolean, name: string, fileName: string, text: string) => {
      logger.log('TeacherScreen: updateFile: isTeacherFile: ', isTeacherFile);

      if (codeDataRef.current.changeType !== 'initialUpdate') {
        const message = {
          type: "updateText",
          isTeacherFile: isTeacherFile,
          teacherName: teacherName,
          name: name,
          fileName: fileName,
          text: text,
          userInfoTeacher: codeDataRef.current.userInfo[teacherName], // only send the teacher userInfo
        };
        if (!loading) {
          logger.log("TS: codeDataRef.current.userInfo[teacherName]", codeDataRef.current.userInfo[teacherName]);
          logger.log('>>>>>> update from teacher, sending message to WS, message:', message);
          ws.current?.send(JSON.stringify(message));
        }
      } else {
        logger.error('TS: getting initialUpdate from lower level, shouldnt be possible');
      }
    }, 300), // 300ms debounce time
    [loading, codeDataRef, ws, teacherName]
  );

  useEffect(() => {
    return () => {
      debouncedUpdateFile.cancel();
    };
  }, [debouncedUpdateFile]);

  const updateFile = useCallback((isTeacherFile: boolean, name: string, fileName: string, text: string) => {
    debouncedUpdateFile(isTeacherFile, name, fileName, text);
  }, [debouncedUpdateFile]);

  
  const switchLesson = useCallback((newLesson: string) => {
    logger.log('>>>>>> TeacherScreen: lesson switched to ', newLesson);
    ws.current?.send(JSON.stringify({ type: 'switchLesson', lesson: newLesson }));
    setLesson(newLesson);
    localStorage.setItem('currentLesson', newLesson);
  }, []);  // do i include setLesson in the dependencies??

  const createLesson = useCallback(() => {
    logger.log('>>>>>> TeacherScreen: createLesson');
    ws.current?.send(JSON.stringify({ type: 'createLesson' }));
  }, []);

  const updateLessonName = useCallback((lessonNameOld: string, lessonNameNew: string) => {
    logger.log('>>>>>> TeacherScreen: updateLessonName, lesson change from ', lessonNameOld, ' to ', lessonNameNew);
    ws.current?.send(JSON.stringify({ type: 'updateLessonName', lessonNameOld: lessonNameOld, lessonNameNew: lessonNameNew }));
  }, []);

  const updateLessonState = useCallback((lessonInfo: LessonInfo) => {
    logger.log('>>>>>> TeacherScreen: updateLessonState, lesson ', lessonInfo.name, ' state changed to ', lessonInfo.state);
    ws.current?.send(JSON.stringify({ type: 'updateLessonState', lessonName: lessonInfo.name, lessonState: lessonInfo.state }));
  }, []);

  const deleteLesson = useCallback((lessonName: string) => {
    logger.log('>>>>>> TeacherScreen: deleteLesson, lesson: ', lessonName);
    ws.current?.send(JSON.stringify({ type: 'deleteLesson', lessonName: lessonName }));
  }, []);

 
 
  const createFile = useCallback((isTeacherFile: boolean, name: string, fileName: string, text: string) => {
    logger.log('>>>>>> TeacherScreen: createFile, isTeacherFile: ', isTeacherFile, ', name: ', name, ', fileName:', fileName, ', text:', text);
    ws.current?.send(JSON.stringify({ type: 'createFile', isTeacherFile: isTeacherFile, name: name, fileName: fileName, text: text }));
  }, []);

  const updateFileName = useCallback((isTeacherFile: boolean, name: string, oldFileName: string, newFileName: string) => {
    logger.log('>>>>>> TeacherScreen: updateFileName, isTeacherFile: ', isTeacherFile, ', name: ', name, ', oldFileName:', oldFileName, 'newFileName:', newFileName);
    ws.current?.send(JSON.stringify({ type: 'updateFileName', isTeacherFile: isTeacherFile, name: name, oldFileName: oldFileName, newFileName: newFileName }));
  }, []);

  const updateFileHidden = useCallback((fileName: string, hidden: boolean) => {
    logger.log('>>>>>> TeacherScreen: updateFileHidden, course: ', courseName, ', lesson: ', lesson, ', fileName: ', fileName, ', hidden changed to ', hidden);
    ws.current?.send(JSON.stringify({ type: 'updateFileHidden', fileName: fileName, hidden: hidden }));
  }, []);


  const updateFilePermission = useCallback((fileName: string, permission: boolean) => {
    logger.log('TS: updateFilePermission, fileName: ', fileName);
    const isConfirmed = window.confirm(`Are you sure you want to ${permission ? '' : 'dis'}allow student editing for file ${fileName}?`);

    if (isConfirmed) {
      if (ws.current) {
        const message = {
          type: 'updateFilePermission',
          fileName: fileName,
          permission: permission
        };
        logger.log('>>>>>> TeacherScreen sending updateFilePermission message: ', message);
        ws.current.send(JSON.stringify(message));
      } else {
        logger.log('Cannot send updateFilePermission message because websocket is not connected');
      }
    }
  }, []);

  const updateFileClassOnly = useCallback((fileName: string, isClassOnly: boolean) => {
    logger.log('TS: updateFileClassOnly called for fileName: ', fileName, ', isClassOnly: ', isClassOnly);

    const message = isClassOnly ? `Are you sure you want to make ${fileName} class only?` : `Are you sure you want to make ${fileName} part of the student starter files?`;

    const isConfirmed = window.confirm(message);

    if (isConfirmed) {
      if (ws.current) {
        const message = {
          type: 'updateFileClassOnly',
          fileName: fileName,
          isClassOnly: isClassOnly
        };
        logger.log('>>>>>> TeacherScreen sending updateFileClassOnly message: ', message);
        ws.current.send(JSON.stringify(message));
      } else {
        logger.log('Cannot send updateFileClassOnly message because websocket is not connected');
      }
    }
  }, []);

  const deleteFile = (isTeacherFile: boolean, name: string, fileName: string) => {
    logger.log('>>>>>> TeacherScreen: deleteFile, isTeacherFile: ', isTeacherFile, ', name: ', name, ', fileName:', fileName);
    ws.current?.send(JSON.stringify({ type: 'deleteFile', isTeacherFile: isTeacherFile, name: name, fileName: fileName }));
  };

  

  const clearRedis = () => {
    logger.log('clearRedis function called');
    const isConfirmed = window.confirm("Are you sure you want to clear the Redis cache? This action cannot be undone.");

    if (isConfirmed) {
      if (ws.current) {
        logger.log('>>>>>> TeacherScreen sending clearRedis message');
        ws.current.send(JSON.stringify({
          type: 'clearRedis',
        }));
      } else {
        logger.log('Cannot send clearRedis message because websocket is not connected');
      }
    }
  };

  const logOut = () => {
    logger.log('logOut function called');
    const isConfirmed = window.confirm("Are you sure you want to log out?");

    if (isConfirmed) {
      if (ws.current) {
        logger.log('>>>>>> TeacherScreen sending logOut message');
        ws.current.send(JSON.stringify({
          type: 'logOut',
        }));
        ws.current.close();  // Explicitly close the WebSocket
        ws.current = null; 
      } else {
        logger.log('Cannot send logOut message because websocket is not connected');
      }
      localStorage.removeItem('teacherName');
      localStorage.removeItem('colorScheme');
      changeColorScheme('dark');
      clearIntervals();
      navigate(`/teacher406816`);
    }
  };

  const handleMenuItemClick = useCallback((menuItem: string) => {
    logger.log('TeacherScreen: handleMenuItemClick: ', menuItem);
    if (menuItem === 'Clear Redis') {
      logger.log('TeacherScreen: Calling clearRedis function');
      clearRedis();
    }
    if (menuItem === 'Log out') {
      logger.log('TeacherScreen: Calling logOut function');
      logOut();
    }
  }, []);

  const setStealthMode = useCallback((newIsStealthMode: boolean) => {
    logger.log('TeacherScreen: setStealthMode, newIsStealthMode: ', newIsStealthMode);
    
    if (ws.current) {
      logger.log('>>>>>> TeacherScreen sending changeStealthMode message');
      ws.current.send(JSON.stringify({
        type: 'updateStealthMode',
        isStealthMode: newIsStealthMode
      }));
    } else {
      logger.log('Cannot send updateStealthMode message because websocket is not connected');
    }
    setIsStealthMode(newIsStealthMode);
  }, [ws]);  

  

 
  if (loading || courseName === '' || lesson === '') {
    return <div>Loading...</div>;
  } else {

  return (
    <div className="editor-container">
      <EditorHeader
        infoMessage={infoMessage}
        connectedMessage={connectedMessage}
        name={teacherName}
        isTeacher={true}
        canClearRedis={true}
        isStealthMode={isStealthMode}
        showStealthToggle={true}
        handleMenuItemClick={handleMenuItemClick}
        setStealthMode={setStealthMode}
      />
      <div className='col-12 code-editor-parent'>
        {teacherName && <CodeEditor 
          codeDataRef={codeDataRef}
          isTeacher={true}
          name={teacherName}
          course={courseName}
          lesson={lesson}
          permissionedFiles={permissions[lesson] || []}

          onSwitchLesson={switchLesson}
          onCreateLesson={createLesson}
          onUpdateLessonName={updateLessonName}
          onUpdateLessonState={updateLessonState}
          onDeleteLesson={deleteLesson}
          
          onCreateFile={createFile}
          onUpdateFile={updateFile}
          onUpdateFileName={updateFileName}
          onUpdateFileHidden={updateFileHidden}
          onUpdateFilePermission={updateFilePermission}
          onUpdateFileClassOnly={updateFileClassOnly}
          onDeleteFile={deleteFile}
        />}
      </div>
    </div>
  );
  }
};

export default TeacherScreen;
