import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import debounce from 'lodash/debounce';

import EditorHeader from './EditorHeader';
import CodeEditor from './CodeEditor';
import { useColorScheme } from './ColorSchemeContext';
import { CodeData, initialCodeData, defaultStealthModeUserInfo, LessonInfo } from './types';
import { logger } from './Logger';


import './StudentScreen.css';


let backendURL = process.env.REACT_APP_BACKEND_WS_URL
if (backendURL == null){
    backendURL = "ws://localhost:8000"
}

type Permissions = {
  [lesson: string]: string[];
};

const StudentScreen: React.FC = () => {
  logger.log('Creating StudentScreen');
  const [lesson, setLesson] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(true);
  const codeDataRef = useRef<CodeData>(initialCodeData);
  const { course = '' } = useParams<{ course?: string }>();
  const [courseName, setCourseName] = useState('');
  const [permissions, setPermissions] = useState<Permissions>({});
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const { changeColorScheme } = useColorScheme();

  // ws variables
  const ws = useRef<WebSocket | null>(null); // Initialize the ref
  // if I can try to move these variables to another component
  // lots of duplicated code in TeacherScreeen
  const [connectedMessage, setConnectedMessage] = useState('(disconnected)');
  const [infoMessage, setInfoMessage] = useState('');
  const reconnectAttempt = useRef<number>(0);
  const websocketStateRef = useRef<'disconnected' | 'connecting' | 'connected'>('disconnected');
  const maxReconnectAttempts = 13;
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [lastPongTimestamp, setLastPongTimestamp] = useState<number | null>(null);
  const pongCheckIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const pingIntMillis = 600000; // 1 minute  ##### SET BACK TO 60000, just put up as annoying for testing
  const pongIntMillis = 600000; // 1 minute
  const pongCheckIntervalMillis = 650000; // 1 minute 5 seconds
  const pongCount = useRef<number>(0);
  const pongCountThreshold = 3;
  const navigate = useNavigate();

    
  // To note: this logic is pretty confusing, there are functions calling each other, there are state variables as well as function parameters and local storage variables
  // is confusing what are the values for these, with the ref there is a stale closure issue, but now with callbacks with dependencies will this change things?
  // the useCallbacks are used to remove the warning, but not sure if this is the best way to do it, also do we want the ws reconnection to be triggered so often
  // I think best if the ws connection is not reset unless the course is changed, which is fine as that will only happen with a url change anyway
  // if ws connection is happening on every lesson change then take out the useCallbacks and just disable the warnings manually

  const clearIntervals = () => {
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
    }

    if (pongCheckIntervalRef.current) {
      clearInterval(pongCheckIntervalRef.current);
    }
  }
  
  // warning suggests to wrap in a useCallback, but cant as needs ref to setupWebSocketHandlers and also not sure how to deal with async
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reconnectWebSocket = async (nameForConnection: string, course: string, lessonParam: string) => {
    if (websocketStateRef.current === 'connecting') {
      logger.log('StudentScreen: reconnectWebsocket: Already attempting to reconnect, skipping additional attempt');
      return;
    }

    websocketStateRef.current = 'connecting';

    if (reconnectAttempt.current === 0) {
      pongCount.current = 0;
    }
    
    let localAttempt: number = reconnectAttempt.current;

    while (localAttempt < maxReconnectAttempts) {
      const delay = Math.pow(2, localAttempt) * 1000; // Exponential backoff delay
      logger.log(`StudentScreen: reconnectWebsocket: Attempt ${localAttempt + 1} to reconnect in ${delay}ms...`);

      reconnectAttempt.current = localAttempt + 1;

      try {
        await new Promise((resolve) => setTimeout(resolve, delay));
        ws.current = new WebSocket(backendURL + `/ws/student?name=${encodeURIComponent(nameForConnection?? "Error")}&course=${encodeURIComponent(course?? "Error")}&lesson=${encodeURIComponent(lessonParam?? "Error")}`);
        setupWebSocketHandlers(nameForConnection, course, lessonParam);
        setLastPongTimestamp(null);
        logger.log('StudentScreen: reconnectWebsocket: connection successful, state set to connected');
        setConnectedMessage('(connected-preliminary)');
        websocketStateRef.current = 'connected';
        break; // If the connection is successful, break out of the loop
      } catch (error) {
        logger.error('StudentScreen: reconnectWebsocket: error caught during reconnection attempt, state => disconnected, error:', error);
        localAttempt++;
        websocketStateRef.current = 'disconnected';
      }
    }

    if (localAttempt === maxReconnectAttempts) {
      logger.log('StudentScreen: reconnectWebsocket: Maximum reconnect attempts reached. Giving up.');
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkPongTimeout = (nameForConnection: string, courseParam: string, lessonParam: string) => {
    if (lastPongTimestamp && Date.now() - lastPongTimestamp > pongCheckIntervalMillis) {
      logger.log('Pong timeout, reconnecting...');
      reconnectWebSocket(nameForConnection, courseParam, lessonParam);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setupWebSocketHandlers = (nameForConnection: string, courseParam: string, lessonParam: string) => {
    if (!ws.current) return;

    ws.current.onopen = () => {
      logger.log('StudentScreen: WS onopen, WebSocket Connected');
      setConnectedMessage('');
      setInfoMessage('');

      // Send initial connection message to server
      if (ws.current) {
        logger.log('>>>>>> StudentScreen sending initial connection message');
        ws.current.send(JSON.stringify({
          type: 'initialConnection',
        }));
      }

      // clear any existing intervals
      clearIntervals();

      pingIntervalRef.current = setInterval(() => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          logger.log('>>>>>> StudentScreen, sending ping:', { type: 'ping' });
          ws.current.send(JSON.stringify({ type: 'ping' }));
        }
      }, pingIntMillis); // Send a ping every x milliseconds (usually 1 minute)

      pongCheckIntervalRef.current = setInterval(() => {
        checkPongTimeout(nameForConnection?? 'Error', courseParam, lessonParam);
      }, pongIntMillis); // Check for pong timeout every x milliseconds (usually 1 minute)
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message.type === 'initialUpdate') {
        // note we can get this message for when the student first connects, or when the student changes the lesson
        logger.log('<<<<<< StudentScreen: Received initialUpdate: ', message);
        // copy over the userInfo with the default just in case, setting default twice not ideal
        codeDataRef.current = {
          ...codeDataRef.current,
          studentFiles: { 
            ...codeDataRef.current.studentFiles,
            [nameForConnection ?? 'Error']: message.studentFiles 
          },
          teacherFiles: message.teacherFiles,
          userInfo: {
            ...message.userInfo,
            [nameForConnection ?? 'Error']: {
              userType: 'student',
              caretPosition: { line: 1, col: 1 },
              highlightedRange: { anchor: { line: 1, col: 1 }, head: { line: 1, col: 1 } },
              activeFile: null,
            }
          },
          changeType: 'initialUpdate',
          studentConnections: message.studentConnections,
          teacherConnections: message.teacherConnections,
          allStudents: message.allStudents,
          lessonsInfo: message.lessonsInfo,
          version: codeDataRef.current.version + 1
        };
        logger.log('StudentScreen WS initialUpdate current vars, course: ', course, ', lesson: ', lesson, ', loading: ', loading);
        if (message.lesson !== lesson) {
          logger.log('StudentScreen WS initialUpdate setting lesson: ', message.lesson);
          localStorage.setItem('currentLesson', message.lesson);
          setLesson(message.lesson);
        }
        logger.log('StudentScreen WS initialUpdate setting course: ', message.course);
        setCourseName(message.course);
        logger.log('StudentScreen WS initialUpdate setting permissions: ', message.permissions);
        setPermissions(message.permissions);
        logger.log('StudentScreen WS initialUpdate setting loading: false');
        setLoading(false);

        forceUpdate();  // force a rerender

      } else if (message.type === 'teacherTextUpdate') {
        logger.log('<<<<<< StudentScreen: Received teacherTextUpdate message: ', message);
        const { fileName, fileText, userInfoName, sentFrom, sentFromTeacher, isStealthMode, teacherTextEditFile, teacherTextEditName } = message;
       
        codeDataRef.current = {
          ...codeDataRef.current,
          teacherFiles: {
            ...codeDataRef.current.teacherFiles,
            [fileName]: {
              ...codeDataRef.current.teacherFiles ? codeDataRef.current.teacherFiles[fileName] : {},
              text: fileText,
            }
          },
          userInfo: {
            ...codeDataRef.current.userInfo,
            [sentFrom]: isStealthMode ? defaultStealthModeUserInfo : userInfoName,
          },
          changeType: isStealthMode ? null : (sentFromTeacher ? 'liveCoding' : 'studentUpdatesTeacher'),
          changeFile: isStealthMode ? null : fileName,
          changeName: isStealthMode ? null : sentFrom,
          teacherTextEditFile: teacherTextEditFile,
          teacherTextEditName: teacherTextEditName,
          version: codeDataRef.current.version + 1
        };
        forceUpdate();  // force a rerender

      } else if (message.type === 'studentTextUpdate') {
        // this is a teacher updating this students text
        logger.log('<<<<<< StudentScreen: Received a studentTextUpdate message: ', message);
        const { studentName, studentFileName, studentFileText, userInfoName, teacherTextEditFile, teacherTextEditName, sentFrom, isStealthMode } = message;
        codeDataRef.current = {
          ...codeDataRef.current,
          studentFiles: {
            ...codeDataRef.current.studentFiles,
            [studentName]: {
              ...(codeDataRef.current.studentFiles ? codeDataRef.current.studentFiles[studentName] : {}),
              [studentFileName]: {
                ...(codeDataRef.current.studentFiles ? codeDataRef.current.studentFiles[studentName][studentFileName] : {}),
                text: studentFileText
              }
            }
          },
          userInfo: {
            ...codeDataRef.current.userInfo,
            [sentFrom]: isStealthMode ? defaultStealthModeUserInfo : userInfoName,
          },
          changeType: isStealthMode ? null : 'teacherUpdatesStudent',  // this must be a teacher changing the student file so therefore teacherUpdateStudent
          changeFile: isStealthMode ? null : studentFileName,
          changeName: isStealthMode ? null : sentFrom,
          teacherTextEditFile: teacherTextEditFile,
          teacherTextEditName: teacherTextEditName,
          version: codeDataRef.current.version + 1
        };
        forceUpdate();  // force a rerender

      // studentStructureUpdate, add this if/when teachers can create/updateName/remove student files

      } else if (message.type === 'teacherStructureUpdate') {
        logger.log('<<<<<< StudentScreen: Received teacherStructureUpdate message: ', message);
        const { teacherFiles, userInfo } = message;
        codeDataRef.current = {
          ...codeDataRef.current,
          teacherFiles: teacherFiles,
          userInfo: userInfo,
          changeType: 'structureUpdate',
          version: codeDataRef.current.version + 1
        };
        forceUpdate();  // force a rerender

      } else if (message.type === 'studentStructureUpdate') {
        // this is not ideal, if a student gets this update, which is an add file, 
        // the student might lose their recent changes that the teacher has not received yet
        logger.log('<<<<<< StudentScreen: Received studentStructureUpdate message: ', message);
        const { studentFiles, userInfo } = message;
        codeDataRef.current = {
          ...codeDataRef.current,
          studentFiles: {
            ...codeDataRef.current.studentFiles,
            [nameForConnection]: studentFiles,
          },
          userInfo: userInfo,
          changeType: 'structureUpdate',
          version: codeDataRef.current.version + 1
        };
        forceUpdate();  // force a rerender

      } else if (message.type === 'teacherCourseUpdate') {
        logger.log('<<<<<< StudentScreen: Received teacherCourseUpdate message: ', message);
        const { lessonsInfo } = message;
        codeDataRef.current = {
          ...codeDataRef.current,
          lessonsInfo: lessonsInfo,
          changeType: 'structureUpdate',
          version: codeDataRef.current.version + 1
        };
        forceUpdate();  // force a rerender

      } else if (message.type === 'connectionUpdate') {
        logger.log('<<<<<< StudentScreen: Received connectionUpdate message: ', message);
        const { studentConnections, teacherConnections, allStudents } = message;
        codeDataRef.current = {
          ...codeDataRef.current,
          studentConnections: studentConnections,
          teacherConnections: teacherConnections,
          allStudents: allStudents,
          changeType: 'structureUpdate',
          version: codeDataRef.current.version + 1
        };
        forceUpdate();  // force a rerender

      } else if (message.type === 'permissionsUpdate') {
        logger.log('<<<<<< StudentScreen: Received permissionsUpdate message: ', message);
        const { permissions } = message;
        setPermissions(permissions);
        forceUpdate();  // force a rerender
        
      } else if (message.type === 'pong') {
        logger.log('<<<<<< StudentScreen: Received pong from server, pongCount.current: ', pongCount.current);
        pongCount.current++;
        if (pongCount.current >= pongCountThreshold && reconnectAttempt.current > 0) {
          logger.log(`StudentScreen: pongCount.current=${pongCount.current}, pongCountThreshold=${pongCountThreshold}, reset reconnection attempts`);
          reconnectAttempt.current = 0;
        }
        setLastPongTimestamp(Date.now());

      } else {
        logger.error('<<<<<< StudentScreen: Unknown message type received from server, message:', message);
      }
    };

    ws.current.onclose = () => {
      logger.error('StudentScreen WS onclose, WS disconnected');
      setConnectedMessage('(disconnected-onclose)');
      reconnectWebSocket(nameForConnection?? 'Error', course, lesson);
    };

    ws.current.onerror = (error) => {
      logger.error('StudentScreen WS onerror Error: ', error);
      setInfoMessage('WS error:' + error.type);

      if (ws.current?.readyState === WebSocket.CLOSED) {
        setConnectedMessage('(disconnected-onerror)');
        reconnectWebSocket(nameForConnection?? 'Error', course, lesson);
      } else {
        logger.log('WS Error but websocket appears to be open so do not reconnect');
      }
    };
  };  // add dependencies to remove warnings, double check this isnt causing issues

  
  useEffect(() => {
    const storedName = localStorage.getItem('name');
    let storedLesson = localStorage.getItem('currentLesson');
    if (storedLesson && storedLesson !== lesson) {
      setLesson(storedLesson?? "Error");
    }
    
    logger.log('StudentScreen useEffect, storedName: ', storedName);
    
    let studentName: string | null = storedName;
    let initialPrompt = true;
    
    
    while (!studentName || studentName.trim() === "" || studentName.includes('-')) {
      if (initialPrompt) {
        studentName = prompt("Please enter your name:");
        initialPrompt = false;
      } else {
        studentName = prompt('Please enter your name (cannot be empty or contain "-"):');
      }
      if (studentName && studentName.trim() !== "" && !studentName.includes('-')) {
        localStorage.setItem('name', studentName);
        
      }
    }
    setName(studentName?? "Error");

    // Connect to WebSocket server
    logger.log('StudentScreen: student ', studentName, ' initially connecting to WS')
    ws.current = new WebSocket(backendURL + `/ws/student?name=${encodeURIComponent(studentName?? "Error")}&course=${encodeURIComponent(course?? "Error")}&lesson=${encodeURIComponent(storedLesson?? "Error")}`);

    setupWebSocketHandlers(studentName, course, storedLesson?? 'Error');

    // Clean up on component unmount
    return () => {
      logger.log('StudentScreen unmounting, clean up');
      if (ws.current) {
        logger.log('StudentScreen unmounting, closing WS');
        ws.current.close();
      } else {
        logger.log('StudentScreen unmounting, WS not open, so not closing it');
      }

      

      logger.log('StudentScreen unmounting, clean up ping and pong intervals');
      clearIntervals();      
     
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  
  // this removes the warning below, I think it is fine like this
  // React Hook useCallback received a function whose dependencies are unknown. Pass an inline function instead     react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateFile = useCallback(
    debounce((isTeacherFile: boolean, relevantName: string, fileName: string, text: string) => {
      // Your existing updateFile logic here
      if (!loading) {
        const info = codeDataRef.current.userInfo[relevantName];
        
        if (codeDataRef.current.changeType !== 'initialUpdate') {
          const message = { 
            type: isTeacherFile ? 'updateTeacherText' : 'updateText', 
            fileName: fileName, 
            text: text, 
            info: info 
          };
          logger.log('>>>>>> StudentScreen: updateFile: sending message:', message);
          ws.current?.send(JSON.stringify(message));
        } else {
          logger.error('SS: updateFile: initialUpdate, how has this come back to updateFile, shouldnt be possible');
        }
      }
    }, 300),
    [loading, codeDataRef, ws]
  );

  useEffect(() => {
    return () => {
      debouncedUpdateFile.cancel();
    };
  }, [debouncedUpdateFile]);

  const updateFile = useCallback((isTeacherFile: boolean, relevantName: string, fileName: string, text: string) => {
    debouncedUpdateFile(isTeacherFile, relevantName, fileName, text);
  }, [debouncedUpdateFile]);

  const switchLesson = useCallback((newLesson: string) => {
    logger.log('>>>>>> StudentScreen: lesson switched to ', newLesson);
    ws.current?.send(JSON.stringify({ type: 'switchLesson', lesson: newLesson }));
    setLesson(newLesson);
    localStorage.setItem('currentLesson', newLesson);
  }, []);

  // naming conventions, local function are called createLesson and callback props are onCreateLesson
  // trying to stick to CRUD, Create, (Read?), Update, Delete, for changing the lesson I have used switch

  const createLesson = useCallback(() => {
    logger.error('SS: this should not happen, cant add lesson from student screen');
  }, []);

  const updateLessonName = useCallback((lessonNameOld: string, lessonNameNew: string) => {
    logger.error('SS: this should not happen, cant edit lesson name from student screen');
  }, []);

  const updateLessonState = useCallback((lessonInfo: LessonInfo) => {
    logger.error('SS: this should not happen, cant change lesson state from student screen');
  }, []);

  const deleteLesson = useCallback((lessonName: string) => {
    logger.error('SS: this should not happen, cant delete lesson from student screen');
  }, []);


  const createFile = useCallback((isTeacherFile: boolean, relevantName: string, fileName: string, text: string) => {
    logger.log('>>>>>> SS: createFile, isTeacherFile: ', isTeacherFile, ', relevantName: ', relevantName, ', fileName:', fileName, 'text:', text);
    ws.current?.send(JSON.stringify({ type: 'createFile', fileName: fileName, text: text }));
  }, [])

  const updateFileName = (isTeacherFile: boolean, relevantName: string, oldFileName: string, newFileName: string) => {
    logger.log('>>>>>> SS: updateFileName, isTeacherFile: ', isTeacherFile, ', relevantName: ', relevantName, ', oldFileName:', oldFileName, 'newFileName:', newFileName);
    ws.current?.send(JSON.stringify({ type: 'updateFileName', oldFileName: oldFileName, newFileName: newFileName }));
  }

  const updateFileHidden = useCallback((fileName: string, hidden: boolean) => {
    logger.error('SS: this should not happen, cant change file hidden from student screen');
  }, []);

  const updateFilePermission = useCallback((fileName: string) => {
    logger.error('SS: updateFilePermission we should not get here, student cant change permissions');
  }, []);

  const updateFileClassOnly = useCallback((fileName: string, isClassOnly: boolean) => {
    logger.error('SS: updateFileClassOnly we should not get here, student cant change classOnly on teacher files');
  }, []);

  const deleteFile = (isTeacherFile: boolean, relevantName: string, fileName: string) => {
    logger.log('>>>>>> SS: onDeleteFile, isTeacherFile: ', isTeacherFile, ', relevantName: ', relevantName, ', fileName:', fileName);
    ws.current?.send(JSON.stringify({ type: 'deleteFile', fileName: fileName }));
  }

  const logOut = () => {
    logger.log('logOut function called');
    const isConfirmed = window.confirm("Are you sure you want to log out?");

    if (isConfirmed) {
      if (ws.current) {
        logger.log('>>>>>> TeacherScreen sending logOut message');
        ws.current.send(JSON.stringify({
          type: 'logOut',
        }));
        ws.current.close();  // Explicitly close the WebSocket
        ws.current = null; 
      } else {
        logger.log('Cannot send logOut message because websocket is not connected');
      }
      localStorage.removeItem('name');
      localStorage.removeItem('colorScheme');
      changeColorScheme('dark');
      clearIntervals();
      navigate(`/`);
    }
  };

  const handleMenuItemClick = useCallback((menuItem: string) => {
    logger.log('SS: handleMenuItemClick: ', menuItem);
    if (menuItem === 'Log out') {
      logOut();
    }
  }, []);

  

  const setStealthMode = useCallback(() => {
    logger.error('SS: setStealthMode we should not get here, student cant set stealth mode');
  }, []);


  if (loading || courseName === '' || lesson === '') {
    return <div>Loading...</div>;
  } else {

    return (
      <div className="editor-container">
        <EditorHeader
          infoMessage={infoMessage}
          connectedMessage={connectedMessage}
          name={name}
          isTeacher={false}
          canClearRedis={false}
          isStealthMode={false}
          showStealthToggle={false}
          handleMenuItemClick={handleMenuItemClick}
          setStealthMode={setStealthMode}
        />
        <div className='col-12 code-editor-parent'>
          {name && <CodeEditor
            codeDataRef={codeDataRef}
            isTeacher={false}
            name={name}
            course={courseName}
            lesson={lesson}
            permissionedFiles={permissions[lesson] || []}

            onSwitchLesson={switchLesson}
            onCreateLesson={createLesson}
            onUpdateLessonName={updateLessonName}
            onUpdateLessonState={updateLessonState}
            onDeleteLesson={deleteLesson}

            onCreateFile={createFile}
            onUpdateFileName={updateFileName}
            onUpdateFile={updateFile}
            onUpdateFileHidden={updateFileHidden}
            onUpdateFilePermission={updateFilePermission}
            onUpdateFileClassOnly={updateFileClassOnly}
            onDeleteFile={deleteFile}
          />}
        </div>
      </div>
    );
  }
};

export default StudentScreen;
