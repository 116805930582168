import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import SplitPane, { Pane, SashContent } from 'split-pane-react';  
import 'split-pane-react/esm/themes/default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab } from 'react-bootstrap';
import { BsDownload, BsClipboard2CheckFill, BsTrashFill } from 'react-icons/bs';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { MdOutlineRestore, MdUndo } from 'react-icons/md';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import FilesPane from './FilesPane';
import CollapseButton from './code-editor/CollapseButton';
import DraggableTabs from './code-editor/DraggableTabs';
import TextHighlightOptions from './code-editor/TextHighlightOptions';
import UserIndicator from './UserIndicator';
import TabContent from './TabContent';

import { logger } from './Logger';
import { CodeData, LessonInfo } from './types';
import { getInitials, isTabCode, getFileNameFromTabName, getFileTypeFromTabName } from './utilities';

import './CodeEditor.css';



interface CollapsedSections {
  [key: string]: boolean;
};


interface CodeEditorProps {
  codeDataRef: React.MutableRefObject<CodeData>;
  isTeacher: boolean;
  name: string;
  course: string;
  lesson: string;
  permissionedFiles: string[];

  onSwitchLesson: (lesson: string) => void;
  onCreateLesson: () => void;
  onUpdateLessonName: (lessonNameOld: string, lessonNameNew: string) => void;
  onUpdateLessonState: (lessonInfo: LessonInfo) => void;
  onDeleteLesson: (lessonName: string) => void;

  onCreateFile: (isTeacherFile: boolean, name: string, fileName: string, text: string) => void;
  onUpdateFileHidden: (fileName: string, hidden: boolean) => void;
  onUpdateFileName: (isTeacherFile: boolean, name: string, oldFileName: string, newFileName: string) => void;
  onUpdateFile: (isTeacherFile: boolean, name: string, fileName: string, text: string) => void;
  onUpdateFilePermission: (fileName: string, permission: boolean) => void;
  onUpdateFileClassOnly: (fileName: string, isClassOnly: boolean) => void;
  onDeleteFile: (isTeacherFile: boolean, name: string, fileName: string) => void;
}


const CodeEditor: React.FC<CodeEditorProps> = ({ 
  codeDataRef,
  isTeacher, 
  name, 
  course, 
  lesson, 
  permissionedFiles,

  onSwitchLesson,
  onCreateLesson,
  onUpdateLessonName,
  onUpdateLessonState,
  onDeleteLesson,

  onCreateFile,
  onUpdateFileHidden,
  onUpdateFileName,
  onUpdateFile,
  onUpdateFilePermission,
  onUpdateFileClassOnly,
  onDeleteFile,
}) => {
  useEffect(() => {
    // This runs after each render
    performance.mark('useEffect-end');

    // Measure and log
    logger.performance('useEffect-start', 'updateFiles-start');
    logger.performance('updateFiles-start', 'updateFiles-end');
    logger.performance('updateFiles-end', 'component-creation');
    logger.performance('component-creation', 'useEffect-end');
    logger.performance('useEffect-start', 'useEffect-end');
    logger.performance('updateFiles-start', 'useEffect-end');

    // Clear all marks and set the start mark for the next cycle
    performance.clearMarks();
    performance.mark('useEffect-start');
  });
  performance.mark('component-creation');

  logger.log('Creating CodeEditor');

  const [currentCodePaneTab, setCurrentCodePaneTab] = useState<string | null>(null);
  const [currentOutputPaneTab, setCurrentOutputPaneTab] = useState<string>('console');
  const [sizes, setSizes] = useState(['15%', '50%', '35%']);
  const [fileAddedRemoved, setFileAddedRemoved] = useState(false);
  const [lastTeacherTextUpdateTimestamp, setLastTeacherTextUpdateTimestamp] = useState<number | null>(null);
  const [lastTeacherTextUpdateTimestampMessage, setLastTeacherTextUpdateTimestampMessage] = useState<number | null>(null);
  const secondsToShowBroadcastMessage = 5;
  const secondsToShowBroadcast = 60;
  const [collapsedSections, setCollapsedSections] = useState<CollapsedSections>({lesson: false, myFiles: false, studentFiles: false, peopleHere: true});
  const [lastTypingTimestamps, setLastTypingTimestamps] = useState<{ [key: string]: { [key: string]: number } }>({});
  const [structureVersion, setStructureVersion] = useState(0);
  const [isFilePaneCollapsed, setIsFilePaneCollapsed] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [highlightOption, setHighlightOption] = useState('code');
  const [showNavButtons, setShowNavButtons] = useState(false);
  const tabContainerRef = useRef<HTMLDivElement>(null);  // these are duplicated, deal with this
  const tabRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});  // these are duplicated, deal with this
  const secondsToShowTypingName = 3;
  const [isLiveCodingMessageHidden, setIsLiveCodingMessageHidden] = useState(false);
  const secondsToHideBroadcastMessage = 60 * 5;
  const [isFullScreenSlides, setIsFullScreenSlides] = useState(false);
  const [isFullScreenQuiz, setIsFullScreenQuiz] = useState(false);
  // const [fileChanges, setFileChanges] = useState<{ [key: string]: number }>({});  
  const fileChangesRef = useRef<{ [key: string]: number }>({});  // changed this to a ref for now to avoid rerenders
  // eventually will have to think about how to handle this
  // These really need to pane specific now, maybe we could move them to the TabContent component or the CodeMirrorEditor component
  const [showRestoreModal, setShowRestoreModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showUndoButton, setShowUndoButton] = useState(false);
  const [isRunningP5, setIsRunningP5] = useState(false);
  const [p5SketchCode, setP5SketchCode] = useState('');
  const [output, setOutput] = useState('');
  const fileRestoreChangeLimit = 10;
  const [backupFiles, setBackupFiles] = useState<{ [key: string]: string }>({});
  const [runKey, setRunKey] = useState(0);
  const [codePaneTabs, setCodePaneTabs] = useState<string[]>([]);
  const [outputPaneTabs, setOutputPaneTabs] = useState<string[]>([]);
  const [isTemplate, setIsTemplate] = useState(false);
  //const [, forceUpdate] = useReducer(x => x + 1, 0);

  const showFFEditorUserIndicator: boolean = false;  // feature flag for editor user indicators
  const showFFTextHighlightOptions: boolean = false;  // feature flag for text highlight options
  const showFFRestoreButton: boolean = true;  // feature flag for restore buttons
  const showFFSubmitButton: boolean = false;  // feature flag for submit buttons
  const showFFLiveCodingOnStudentFiles: boolean = false;  // feature flag for live coding on student files

  

  
// rules for where to open different file types by default, codePane is the default unless specified
  // first key is the user type, second key is the file type, value is the array of extensions that should open in the outputPane
  const outputPaneRules = {
    teacher: {
      teacher: ['txt', 'slides', 'quiz'],
      student: ['txt', 'slides', 'quiz']
    },
    student: {
      teacher: ['txt', 'slides', 'quiz'],
      student: ['txt', 'slides', 'quiz']
    }
  };

  // at the top all the useEffects and functions called from these useEffects

  const adjustSectionHeights = useCallback(() => {
    // !!!!!!!!!!!!!!!!!!!!!!!! this should be in FilesPane


    // this seems like quite a lot of complexity just for multiple sections that grow with content, maybe a straight split pane is better
    // this is also triggering on all updates which would be a lot of unnecessary work, must be a better way to do this
    logger.log('CE: adjustSectionHeights called');

    // to note: I think this should be moved to a useLayoutEffect, see code below
    // const adjustSectionHeightsRef = useRef(adjustSectionHeights);

    // useEffect(() => {
    //   adjustSectionHeightsRef.current = adjustSectionHeights;
    // }, [collapsedSections]);

    // useLayoutEffect(() => {
    //   adjustSectionHeightsRef.current();
    // }, [/* dependencies that should trigger this effect */]);

    const container = document.querySelector('.files-list-container-container') as HTMLElement | null;
    const sections = document.querySelectorAll('.files-list-container') as NodeListOf<HTMLElement>;
    const sectionsEmpty = document.querySelectorAll('.files-list-container-empty') as NodeListOf<HTMLElement>;

    if (!container) {
      logger.log('adjustSectionHeights Container not found');
      return;
    }

    const containerHeight = container.clientHeight;

    // Calculate total scroll height of all sections including titles
    let totalScrollHeight = 0;
    let totalTitlePaddingHeight = 0;
    sections.forEach(section => {
      const title = section.querySelector('.file-name-title') as HTMLElement | null;
      const sectionName = title?.id;
      //logger.log('sectionName: ', sectionName);
      let titleHeight = title?.clientHeight || 0;
      if (title) {
        const titleMargin = parseFloat(getComputedStyle(title).marginTop) + parseFloat(getComputedStyle(title).marginBottom);
        titleHeight += titleMargin;
      }
      const scrollContainer = section.querySelector('.files-vertical-scroll') as HTMLElement;
      const sectionPadding = parseFloat(getComputedStyle(section).paddingTop) + parseFloat(getComputedStyle(section).paddingBottom);
      totalTitlePaddingHeight += titleHeight + sectionPadding;
      
      if (scrollContainer && sectionName) {
        const isSectionCollapsed = sectionName in collapsedSections ? collapsedSections[sectionName] : false;
        if (!isSectionCollapsed) {
          scrollContainer.style.height = 'auto';
          totalScrollHeight += scrollContainer.scrollHeight;
        }
      }
    });

    const fullHeight = totalScrollHeight + totalTitlePaddingHeight;
    
    // Check if the total scroll height exceeds the container height
    if (fullHeight > containerHeight) {
      // Calculate the proportion of each section's height relative to the total scroll height
      let bigScrollHeights = 0;
      let bigScrollHeightAllocation = containerHeight - totalTitlePaddingHeight;
      sections.forEach(section => {
        const scrollContainer = section.querySelector('.files-vertical-scroll') as HTMLElement;
        const title = section.querySelector('.file-name-title') as HTMLElement | null;
        const sectionName = title?.id;
        if (scrollContainer && sectionName) {
          const isSectionCollapsed = sectionName in collapsedSections ? collapsedSections[sectionName] : false;
          if (!isSectionCollapsed) {
            const proportion = scrollContainer.scrollHeight / totalScrollHeight;
            if (proportion > 0.25) {
              bigScrollHeights += scrollContainer.scrollHeight;
            } else {
              bigScrollHeightAllocation -= scrollContainer.scrollHeight;
            }
          }
        }
      });

      // possible timing bug here, bit hacky but should be fine
      sections.forEach(section => {
        const scrollContainer = section.querySelector('.files-vertical-scroll') as HTMLElement;
        const title = section.querySelector('.file-name-title') as HTMLElement | null;
        const sectionName = title?.id;
        if (scrollContainer && sectionName) {
          const isSectionCollapsed = sectionName in collapsedSections ? collapsedSections[sectionName] : false;
          if (!isSectionCollapsed) {
            const proportion = scrollContainer.scrollHeight / totalScrollHeight;
            if (proportion > 0.25) {
              const bigScrollProportion = scrollContainer.scrollHeight / bigScrollHeights;
              const newScrollHeight = bigScrollHeightAllocation * bigScrollProportion;
              scrollContainer.style.height = `${newScrollHeight}px`;
            } else {
              scrollContainer.style.height = `${scrollContainer.scrollHeight}px`;
            }
          } else {
            scrollContainer.style.height = '0px';
          }
        }
      });
      sectionsEmpty.forEach(section => {
        section.style.height = '0px';
      });

    } else {
      sections.forEach(section => {
        const scrollContainer = section.querySelector('.files-vertical-scroll') as HTMLElement;
        if (scrollContainer) {
          scrollContainer.style.height = 'auto';
        }
      });
      sectionsEmpty.forEach(section => {
        section.style.height = `${containerHeight - totalScrollHeight - totalTitlePaddingHeight}px`;
      });
    }
  }, [collapsedSections]);

  useEffect(() => {
    logger.log('CE: useEffect(adjustSectionHeights), calling adjustSectionHeights');
    window.addEventListener('resize', adjustSectionHeights);
    adjustSectionHeights(); // Initial call

    return () => {
      window.removeEventListener('resize', adjustSectionHeights);
    };
  }, [adjustSectionHeights]);

  
  useEffect(() => {
    logger.log('CE: useEffect(fileAddedRemoved, collapsedSections, adjustSectionHeights) fileAddedRemoved changed to: ', fileAddedRemoved);
    adjustSectionHeights(); 
    setFileAddedRemoved(false);
  }, [fileAddedRemoved, collapsedSections, adjustSectionHeights]);
  

  useEffect(() => {
    logger.log('CE: useEffect(codeData), setting timers and default files, codeData.changeType: ', codeDataRef.current.changeType, ', codeData : ', codeDataRef.current);

    let adjustSectionHeightsTimer: NodeJS.Timeout | null = null;
    let timeout: NodeJS.Timeout | null = null;
    let timeoutMessage: NodeJS.Timeout | null = null;

    if (codeDataRef.current.changeType === 'liveCoding' || codeDataRef.current.changeType === 'teacherUpdatesStudent' ||
        codeDataRef.current.changeType === 'studentUpdatesSelf' || codeDataRef.current.changeType === 'studentUpdatesTeacher'
    ) {
      if (codeDataRef.current?.changeName && codeDataRef.current?.changeFile) {
        setLastTypingTimestamps(prev => ({
          ...prev, 
          [codeDataRef.current.changeFile?? 'unknownFile']: {
            ...prev[codeDataRef.current.changeFile?? 'unknownFile'],
            [codeDataRef.current.changeName?? 'unknownName']: Date.now()
          }
        }));
      }
    }

    // need to find a way to split out the liveCoding and the teacherUpdateStudent
    if (codeDataRef.current.changeType === 'liveCoding') {
      logger.log('CE: useEffect(codeData), codeDataRef.current.changeType === liveCoding, setLastTeacherTextUpdateTimestamp, ..Message : ', Date.now());
      setLastTeacherTextUpdateTimestamp(Date.now());
      setLastTeacherTextUpdateTimestampMessage(Date.now());

      // Clear the previous timeout if it exists
      if (timeout) {
        clearTimeout(timeout);
      }
      if (timeoutMessage) {
        clearTimeout(timeoutMessage);
      }

      // Set a new timeout to clear the broadcast icon after the specified duration
      timeout = setTimeout(() => {
        setLastTeacherTextUpdateTimestamp(null);
      }, secondsToShowBroadcast * 1000);

      timeoutMessage = setTimeout(() => {
        setLastTeacherTextUpdateTimestampMessage(null);
      }, secondsToShowBroadcastMessage * 1000);

    } else if (codeDataRef.current.changeType === 'teacherUpdatesStudent') {
      setLastTeacherTextUpdateTimestamp(Date.now());

      // Clear the previous timeout if it exists
      if (timeout) {
        clearTimeout(timeout);
      }

      // Set a new timeout to clear the broadcast icon after the specified duration
      timeout = setTimeout(() => {
        setLastTeacherTextUpdateTimestamp(null);
      }, secondsToShowBroadcast * 1000);

    } else if (codeDataRef.current.changeType === 'initialUpdate') {
      logger.log('CE UE(codeData), initial update, setting default files, lesson: ', lesson);
      const availableFiles = isTeacher 
        ? Object.keys(codeDataRef.current.teacherFiles === null ? {} : codeDataRef.current.teacherFiles)
        : Object.keys(codeDataRef.current.studentFiles === null ? {} : codeDataRef.current.studentFiles[name]);

      const teacherFiles = Object.keys(codeDataRef.current.teacherFiles === null ? {} : codeDataRef.current.teacherFiles)

      logger.log('CE UE(codeData), availableFiles: ', availableFiles);
      
      // Find the first .py file
      const firstPyFile = availableFiles.find(file => (file.endsWith('.py') || file.endsWith('.js')));
      if (firstPyFile) {
        logger.log('Setting currentFile to first .py file:', firstPyFile);
        const TabName = isTeacher ? `teacher-name-${firstPyFile}` : `student-${name}-${firstPyFile}`;
        setCurrentCodePaneTabWrapper(TabName);
        setCodePaneTabs([TabName]);
      } else {
        logger.log('No .py files found');
        setCurrentCodePaneTabWrapper(null);
        setCodePaneTabs([]);
      }
  
      // Find the first .txt file
      const firstTxtFile = teacherFiles.find(file => file.endsWith('.txt'));
      if (firstTxtFile) {
        logger.log('Setting output pane tab to first .txt file:', firstTxtFile);
        setCurrentOutputPaneTabWrapper('teacher-name-' + firstTxtFile);
        setOutputPaneTabs(['teacher-name-' + firstTxtFile]);
      } else {
        setCurrentOutputPaneTabWrapper('console');
        setOutputPaneTabs([]);
        logger.log('No .txt files found');
      }

      const checkTemplateState = () => {
        const { lessonsInfo } = codeDataRef.current;
        
        if (lessonsInfo.length === 0) {
          setIsTemplate(false);
          return;
        }
  
        const templateLessons = lessonsInfo.filter(lesson => lesson.state === 'template');
  
        if (templateLessons.length === lessonsInfo.length) {
          setIsTemplate(true);
        } else if (templateLessons.length > 0) {
          setIsTemplate(true);
          logger.warn('Some lessons are templates, but not all. This should never happen.');
        } else {
          setIsTemplate(false);
        }
      };
  
      checkTemplateState();
      codeDataRef.current.changeType = 'selfUpdate';  // bit of a hack, but was a bug with initial update getting triggered multiple times
    } else if (codeDataRef.current.changeType === 'structureUpdate') {
      setFileAddedRemoved(true);
      setStructureVersion(prev => prev + 1);  // !!!!!!!!!!!!!!!!!!!!!!!!! think what is happening here, this should trigger an update in FilesPane
      // but somehow avoid the rerender for the CodeAndOutputPane, think about this
    }

    // why are we rerendering here, is we set currentFile or openTabs it should force a rerender anyway
    // forceUpdate();  // force a rerender

    // Cleanup function to clear the timeout when the component unmounts or the effect is re-run
    return () => {
      if (adjustSectionHeightsTimer) {
        clearTimeout(adjustSectionHeightsTimer);
      }
      if (timeout) {
        clearTimeout(timeout);
      }
      if (timeoutMessage) {
        clearTimeout(timeoutMessage);
      }
    };
  }, [codeDataRef.current.version, codeDataRef, isTeacher, lesson, name]);  // added extra dependencies to fix warning, should be fine but check

  
  const checkOverflow = useCallback(() => {
    if (tabContainerRef.current) {
      const isOverflowing = tabContainerRef.current.scrollWidth > tabContainerRef.current.clientWidth;
      setShowNavButtons(isOverflowing);
    }
  }, []);

  useEffect(() => {
    logger.log('CE: useEffect(checkOverflow), setting resizeObserver observing tabContainerRef');
    const resizeObserver = new ResizeObserver(checkOverflow);
    if (tabContainerRef.current) {
      resizeObserver.observe(tabContainerRef.current);
    }
    
    return () => {
      if (tabContainerRef.current) {
        resizeObserver.unobserve(tabContainerRef.current);
      }
    };
  }, [checkOverflow]);

  
  useEffect(() => {
    logger.log('CE: useEffect(currentCodePaneTab, currentOutputPaneTab), closing submit, restore modals');
    if (showSubmitModal) { setShowSubmitModal(false); }
    if (showRestoreModal) { setShowRestoreModal(false); }
  }, [currentCodePaneTab, currentOutputPaneTab]);

  
  useEffect(() => {
    // Note: this has an issue, you cannot tell if this is triggered first or the useEffect(codeData) which handles initial update
    // for now do nothing and let the initial update handle it
    logger.log('CE: useEffect(lesson), new lesson, at the moment do nothing');
    // setCurrentCodePaneTabWrapper(null);
    // setCodePaneTabs([]);
    // setCurrentOutputPaneTabWrapper('console');
    // setOutputPaneTabs([]);
  }, [lesson])

  
  useEffect(() => {
    logger.log('CE: useEffect(showSubmitModal, showRestoreModal), add or remove the modal-open');
    if (showSubmitModal || showRestoreModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [showSubmitModal, showRestoreModal]);


  useLayoutEffect(() => {
    logger.log('CE: useLayoutEffect(codePaneTabs, checkOverflow), checkOverflow and add resize listener');
    // !!! This needs fixing, should resize for both panes, maybe move this into DraggableTabs component
    checkOverflow();
    
    // Add resize listener
    window.addEventListener('resize', checkOverflow);
    
    // Cleanup
    return () => window.removeEventListener('resize', checkOverflow);
  }, [codePaneTabs, checkOverflow]);

  

  const switchLesson = useCallback((lesson: string) => {
    logger.log('switchLesson, blanking out tabs lesson: ', lesson);
    setCurrentCodePaneTabWrapper(null);
    setCodePaneTabs([]);
    setCurrentOutputPaneTabWrapper('console');
    setOutputPaneTabs([]);
    onSwitchLesson(lesson);
  }, []);

  
  const toggleFullScreenSlides = useCallback(() => {
    setIsFullScreenSlides(prev  => !prev );
  }, []);
  
  const handleSubmit = () => {
    setShowRestoreModal(false);
    setShowSubmitModal(true);
  };

  const handleSubmitConfirm = () => {
    setShowSubmitModal(false);
  };
  
  const handleSubmitCancel = () => {
    setShowSubmitModal(false);
  };

  const handleRestore = () => {
    setShowSubmitModal(false);
    setShowRestoreModal(true);
  };


  

  // Utility functions for restore functionality
  
  
  const hasOriginalFile = (isCodePane: boolean) => {
    if (isCodePane && (currentCodePaneTab === null || codeDataRef.current?.teacherFiles === null)) { return false; }
    if (!isCodePane && (currentOutputPaneTab === null || codeDataRef.current?.teacherFiles === null)) { return false; }
    const originalFileName = getFileNameFromTabName(isCodePane ? currentCodePaneTab?? '' : currentOutputPaneTab?? '');

    const teacherFiles = codeDataRef.current?.teacherFiles === undefined ? {} : codeDataRef.current?.teacherFiles;
    return originalFileName in (teacherFiles?? {});
  };

  

  
  const handleRestoreConfirm = (isCodePane: boolean) => {
    logger.log('CodeEditor: handleRestoreConfirm called');
    const currentFile = isCodePane? currentOutputPaneTab : currentCodePaneTab;
    if (currentFile === null || codeDataRef.current.teacherFiles === null || codeDataRef.current.studentFiles === null || codeDataRef.current.studentFiles[name] === null) {
      logger.error('CodeEditor: handleRestoreConfirm called with a null in studentFiles or teacherFiles');
      return;
    }

    const fileName = getFileNameFromTabName(currentFile);
    const classText = codeDataRef.current.teacherFiles[fileName].text;
    
    // Save backup
    const currentText = codeDataRef.current.studentFiles[name][fileName].text;
    setBackupFiles(prev => ({
      ...prev,
      [`backupFile:${lesson}:${fileName}`]: currentText
    }));
  
    // Restore file
    if (codeDataRef.current.studentFiles === null) {
      codeDataRef.current = {
        ...codeDataRef.current,
        studentFiles: {
          [name]: {
            [fileName]: {
              text: classText,
              newFile: false
            }
          }
        }
      };
    } else {
      const updatedStudentFiles = { ...codeDataRef.current.studentFiles };
      if (!updatedStudentFiles[name]) {
        updatedStudentFiles[name] = {};
      }

      updatedStudentFiles[name] = {
        ...updatedStudentFiles[name],
        [fileName]: {
          text: classText,
          newFile: updatedStudentFiles[name][fileName]?.newFile ?? false
        }
      };
    
      codeDataRef.current = {
        ...codeDataRef.current,
        studentFiles: updatedStudentFiles
      };
    }
  
    // Reset changes
    fileChangesRef.current = { ...fileChangesRef.current, [fileName]: 0 };
    setShowRestoreModal(false);
    setShowUndoButton(true);
  };

  
  const handleUndo = () => {
    // currently this only handles code pane files, need to make this for both panes or move this to TabContent or CodeMirrorEditor
    if (currentCodePaneTab === null || codeDataRef.current.studentFiles === null || codeDataRef.current.studentFiles[name] === null) {
      logger.error('CodeEditor: handleUndo called with something null');
      return;
    }
    const fileName = getFileNameFromTabName(currentCodePaneTab);
    const backupContent = backupFiles[`backupFile:${lesson}:${fileName}`];

    if (backupContent === undefined) {
      logger.error(`CodeEditor: No backup content found for ${fileName}`);
      setShowUndoButton(false);
      return;
    }
   
    codeDataRef.current = {
      ...codeDataRef.current,
      studentFiles: {
        ...codeDataRef.current.studentFiles,
        [name]: {
          ...(codeDataRef.current.studentFiles ? codeDataRef.current.studentFiles[name] : {}),
          [fileName]: {
            newFile: (codeDataRef.current.studentFiles ? codeDataRef.current.studentFiles[name]?.[fileName]?.newFile ?? false : false),
            text: backupContent
          }
        }
      }
    };

    setShowUndoButton(false);
    
  };
  
  const handleRestoreCancel = () => {
    setShowRestoreModal(false);
  };


  // add this back in when we fix revert to original
  const incrementFileChanges = (fileName: string) => {
    logger.log('incrementFileChanges: Current file changes: ', fileChangesRef.current, ', fileName: ', fileName);
    fileChangesRef.current = {
      ...fileChangesRef.current,
      [fileName]: (fileChangesRef.current[fileName] || 0) + 1
    }
  };
  
  const hasEnoughChangesToRestore = () => {
    if (currentCodePaneTab === null) { return false; }
    const originalFileName = getFileNameFromTabName(currentCodePaneTab);
    if (originalFileName in fileChangesRef.current) {
      return fileChangesRef.current[originalFileName] >= fileRestoreChangeLimit;
    } else {
      logger.log('hasEnoughChangesToRestore: currentFile not in fileChanges set to 0, fileChanges: ', fileChangesRef.current);
      fileChangesRef.current[originalFileName] = 0;
    }
    return fileChangesRef.current[originalFileName] >= fileRestoreChangeLimit
  }
  
  const hideLiveCodingMessage = () => {
    logger.log('CE: hideLiveCodingMessage called');
    setIsLiveCodingMessageHidden(true);
    setTimeout(() => {
      setIsLiveCodingMessageHidden(false);
    }, secondsToHideBroadcastMessage * 1000); // 5 minutes in milliseconds
  };

  
  const toggleFullScreenQuiz = useCallback(() => {
    setIsFullScreenQuiz(prev => !prev);
  }, []);


  const createFile = useCallback((fileType: 'student' | 'teacher', studentName?: string) => {
    logger.log('CodeEditor: createFile fileType:', fileType, ', studentName: ', studentName);
    if (codeDataRef.current.studentFiles === null || codeDataRef.current.teacherFiles === null) {
      logger.error('CodeEditor: createFile for ', name, ': studentFiles or teacherFiles is null');
      return;
    }
    let newTabName = '';
    if (fileType === 'student') {
      newTabName = `file${Object.keys(codeDataRef.current.studentFiles[studentName ?? ''] ?? []).length + 1}.py`;
    } else {
      newTabName = `file${Object.keys(codeDataRef.current.teacherFiles).length + 1}.py`;
    }  
    
    const [success, uniqueFileName] = getNewName(newTabName, fileType, studentName);
    if (success) {
      // Update codeDataRef.current directly
      codeDataRef.current = {
        ...codeDataRef.current,
        changeType: 'currentFile',
      };

      if (fileType === 'student') {
        if (codeDataRef.current.studentFiles !== null) {
          codeDataRef.current.studentFiles = {
            ...codeDataRef.current.studentFiles,
            [studentName ?? '']: {
              ...codeDataRef.current.studentFiles[studentName ?? ''],
              [uniqueFileName]: {
                text: '',
                newFile: true,
              },
            },
          };
        } else {
          codeDataRef.current.studentFiles = {
            [studentName ?? '']: {
              [uniqueFileName]: {
                text: '',
                newFile: true,
              },
            },
          };
        }
      } else {
        if (codeDataRef.current.teacherFiles !== null) {
          codeDataRef.current.teacherFiles = {
            ...codeDataRef.current.teacherFiles,
            [uniqueFileName]: {
              text: '',
              hidden: false,
              classOnly: false
            }
          };
        } else {
          codeDataRef.current.teacherFiles = {
            [uniqueFileName]: {
              text: '',
              hidden: false,
              classOnly: false
            }
          };
        }
      }

      const usedStudentName = studentName?.replace(/-/g, '_') ?? 'blank';
      const tabName = (fileType === 'student') ? `student-${usedStudentName}-${uniqueFileName}` : `teacher-name-${uniqueFileName}`;
      addTabFromTabName(tabName);
      // setFileAddedRemoved(true);  // this was meant for triggering a rerender I think or a resize of the files sections, revisit this with the new component
      const relevantName = fileType === 'teacher' ? name : usedStudentName;
      onCreateFile((fileType === 'teacher'), relevantName, uniqueFileName, '');
    } else {
      alert('Too many copies of the file exist. Please choose a different name.');
    }
  }, [name]);


  const getNewName = (existingName: string, fileType: 'student' | 'teacher', studentName?: string): [boolean, string] => {
    logger.log('getNewName: existingName: ', existingName, ', fileType: ', fileType);
    let existingFiles: { [fileName: string]: { text: string, hidden?: boolean, class_only?: boolean, student_only?: boolean } } = {};

    if (fileType === 'student') {
      existingFiles = codeDataRef.current.studentFiles ? codeDataRef.current.studentFiles[studentName ?? ''] : {};
    } else {
      existingFiles = codeDataRef.current.teacherFiles ?? {};
    }
    logger.log('getNewName: existingFiles:', existingFiles);
  
    const lastDotIndex = existingName.lastIndexOf('.');
    const nameWithoutExtension = lastDotIndex !== -1 ? existingName.slice(0, lastDotIndex) : existingName;
    const extension = lastDotIndex !== -1 ? existingName.slice(lastDotIndex) : '';
  
    for (let counter = 0; counter <= 100; counter++) {
      const suffix = counter === 0 ? '' : `(copy${counter})`;
      const newName = `${nameWithoutExtension}${suffix}${extension}`;
      
      if (!(newName in existingFiles)) {
        logger.log('newName isnt used, return it, newName: ', newName);
        return [true, newName];
      }
      logger.log('try with another newName: ', newName);
    }
  
    return [false, existingName];
  };

  
  const updateFileName = useCallback((oldFileName: string, newFileName: string, fileType: 'student' | 'teacher', studentName?: string) => {
    logger.log('updateFileName: oldFileName: ', oldFileName, ', newFileName: ', newFileName, ', fileType: ', fileType, ', studentName: ', studentName);

    if (!(newFileName.endsWith('.py') || newFileName.endsWith('.js')) && fileType === 'student') {
      alert('You can only rename files to names ending with .py');
      return;
    }

    if (oldFileName !== newFileName) {
      
      const [success, uniqueFileName] = getNewName(newFileName, fileType, studentName);
      if (success) {
        // Update codeDataRef.current directly
        codeDataRef.current = {
          ...codeDataRef.current,
          changeType: 'currentFile',
        };

        if (fileType === 'student') {
          if (codeDataRef.current.studentFiles === null) {
            logger.error('Error codeDataRef.current.studentFiles === null cannot updateFileName, shouldnt get here');
            return;
          }
          const updatedFiles = { ...codeDataRef.current.studentFiles[studentName ?? ''] };
          const fileOrder = Object.keys(updatedFiles);
          const oldIndex = fileOrder.indexOf(oldFileName);
          if (oldIndex !== -1) {
            fileOrder.splice(oldIndex, 1);
            fileOrder.splice(oldIndex, 0, uniqueFileName);
          }
          const reorderedFiles: { [key: string]: {text: string, newFile: boolean} } = {};
          fileOrder.forEach((file) => {
            reorderedFiles[file] = file === uniqueFileName ? updatedFiles[oldFileName] : updatedFiles[file];
          });
          codeDataRef.current.studentFiles = {
            ...codeDataRef.current.studentFiles,
            [studentName ?? '']: reorderedFiles,
          };
        } else {
          if (codeDataRef.current.teacherFiles === null) {
            logger.error('Error codeDataRef.current.teacherFiles === null cannot updateFileName, shouldnt get here');
            return;
          }
          const updatedFiles = { ...codeDataRef.current.teacherFiles };
          const fileOrder = Object.keys(updatedFiles);
          const oldIndex = fileOrder.indexOf(oldFileName);
          if (oldIndex !== -1) {
            fileOrder.splice(oldIndex, 1);
            fileOrder.splice(oldIndex, 0, uniqueFileName);
          }
          const reorderedFiles: { [key: string]: {text: string, hidden: boolean, classOnly: boolean} } = {};
          fileOrder.forEach((file) => {
            reorderedFiles[file] = file === uniqueFileName ? updatedFiles[oldFileName] : updatedFiles[file];
          });
          codeDataRef.current.teacherFiles = reorderedFiles;
        }
        
        const usedStudentName = studentName?.replace(/-/g, '_') ?? 'blank';
        const oldTabName = (fileType === 'student') ? `student-${usedStudentName}-${oldFileName}` : `teacher-name-${oldFileName}`;
        const newTabName = (fileType === 'student') ? `student-${usedStudentName}-${uniqueFileName}` : `teacher-name-${uniqueFileName}`;
        if (codePaneTabs.includes(oldTabName)) {
          setCodePaneTabs((prev) => prev.map((tabName) => (tabName === oldTabName ? newTabName : tabName)));
          setCurrentCodePaneTabWrapper(newTabName);
        } else if (outputPaneTabs.includes(oldTabName)) {
          setOutputPaneTabs((prev) => prev.map((tabName) => (tabName === oldTabName ? newTabName : tabName)));
          setCurrentOutputPaneTabWrapper(newTabName);
        }
        
        // setRenameFileName('');  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! this needs fixing it will not work as expected
        const isTeacherFile = (fileType === 'teacher');
        const relevantName = isTeacher ? (isTeacherFile ? name : studentName?? 'Error') : name;
        onUpdateFileName(isTeacherFile, relevantName, oldFileName, uniqueFileName);
      } else {
        alert('Too many copies of the file exist. Please choose a different name.');
      }  
    }
  }, [name, isTeacher, onUpdateFileName, getNewName, codePaneTabs, outputPaneTabs]);

  
  const shouldShowCopyButton = (tabName: string) => {
    const [fileType, ...rest] = tabName.split('-');
    const fileName = rest.pop()?? '';
    return !isTeacher && 
      (fileType == 'teacher') && 
      codeDataRef.current.studentFiles &&
      !codeDataRef.current.studentFiles[name].hasOwnProperty(fileName);
  };

  const getNamesTyping = (fileName: string) => {
    // Note this function is not ideal
    // it is not functionally perfect, it checks within 3 secs on a rerender and sends typing to CME
    // the CME will show the name tag and clear after 3 secs, meaning if another rerender happens the name tag can show up to 6 secs
    // this could be fixed by sending in a the lastTypingTimestamp to CME and the delayed end to be calced based on that
    // maybe also we should clear names that have not been typing or not typing for x seconds, this prevents extra checks that are always false
    // logger.log('CE: GNT, fileName: ', fileName, ', lastTypingTimestamp: ', lastTypingTimestamp);
    // Check if fileName is not in lastTypingTimestamp and if not return []
    if (!(fileName in lastTypingTimestamps)) {
      return [];
    }

    const currentTime = Date.now();
    const namesTyping: string[] = [];

    // For each name in lastTypingTimestamp[fileName], check if the elapsed time is less than the secondsToShowTypingName
    for (const [name, timestamp] of Object.entries(lastTypingTimestamps[fileName])) {
      const elapsedTime = (currentTime - timestamp) / 1000; // Convert to seconds
      // logger.log('CE: GNT, name: ', name, ', elapsedTime:', elapsedTime);
      if (elapsedTime < secondsToShowTypingName) {
        namesTyping.push(name);
      }
    }

    // Return namesTyping array
    return namesTyping;
  };


  const onDragEnd = (result: any) => {
    const { source, destination } = result;
    logger.log('onDragEnd, source: ', source, ', destination: ', destination);

    if (!destination) { return; }
    if ( 
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) { return; }
  
    const sourcePane = source.droppableId;
    const destPane = destination.droppableId;
  
    const sourceTabs = sourcePane === 'codePane' ? [...codePaneTabs] : [...outputPaneTabs];
    const destTabs = destPane === 'codePane' ? [...codePaneTabs] : [...outputPaneTabs];

    logger.log('onDragEnd, sourcePane: ', sourcePane, ', destPane: ', destPane);
    logger.log('onDragEnd, sourceTabs: ', sourceTabs, ', destTabs: ', destTabs);
  
    // Remove the dragged item from the source array
    const [removed] = sourceTabs.splice(source.index, 1);

    // If the source and destination are the same, remove the item from the destination array
    // This will be the pane that the item will be added back into
    if (sourcePane === destPane) {
      destTabs.splice(source.index, 1);
    }
    
    // Add the dragged item to the destination array
    destTabs.splice(destination.index, 0, removed);
  
    // Update the state based on whether it's an inter-pane or intra-pane drag
    // This is a drag from one tab section to the same tab section
    if (sourcePane === destPane) {
      if (sourcePane === 'codePane') {
        setCurrentCodePaneTabWrapper(removed);
        setCodePaneTabs(destTabs);
      } else {
        setCurrentOutputPaneTabWrapper(removed);
        setOutputPaneTabs(destTabs);
      }

    // This is a drag from one tab section to a content section, might be same or other
    } else if (destPane ==='editor-content') {
      if (sourcePane === 'codePane') {
        sourceTabs.push(removed);
        setCurrentCodePaneTabWrapper(removed);
        setCodePaneTabs(sourceTabs);
      } else if (sourcePane === 'outputPane') {
        setOutputPaneTabs(sourceTabs);
        setCurrentOutputPaneTabWrapper(sourceTabs.length > 0 ? sourceTabs[sourceTabs.length - 1] : 'console');
        setCodePaneTabs(prev => [...prev, removed]);
        setCurrentCodePaneTabWrapper(removed);
      }

    // This is a drag from one tab section to a content section, might be same or other
    } else if (destPane ==='output-section') {
      if (sourcePane === 'codePane') {
        setCodePaneTabs(sourceTabs);
        setCurrentCodePaneTabWrapper(sourceTabs.length > 0 ? sourceTabs[sourceTabs.length - 1] : null);
        setOutputPaneTabs(prev => [...prev, removed]);
        setCurrentOutputPaneTabWrapper(removed);
      } else if (sourcePane === 'outputPane') {
        sourceTabs.push(removed);
        setCurrentOutputPaneTabWrapper(removed);
        setOutputPaneTabs(sourceTabs);
      }

    // this should be a drag from one tab section to the other
    } else {
      setCodePaneTabs(sourcePane === 'codePane' ? sourceTabs : destTabs);
      setOutputPaneTabs(sourcePane === 'outputPane' ? sourceTabs : destTabs);
      if (destPane === 'codePane') {
        setCurrentCodePaneTabWrapper(removed);
        setCurrentOutputPaneTabWrapper(sourceTabs.length > 0 ? sourceTabs[sourceTabs.length - 1] : 'console');
      } else {
        setCurrentOutputPaneTabWrapper(removed);
        setCurrentCodePaneTabWrapper(sourceTabs.length > 0 ? sourceTabs[sourceTabs.length - 1] : null);
      }
    }
  };

  const removeTab = (tabName: string, paneId: 'codePane' | 'outputPane') => {
    logger.log('removeTab: tabName: ', tabName, ', paneId: ', paneId);
    const remainingTabs = (paneId === 'codePane' ? [...codePaneTabs] : [...outputPaneTabs]).filter(id => id !== tabName);

    if (paneId === 'codePane') {
      if (tabName === currentCodePaneTab) {
        if (remainingTabs.length >= 1) {
          setCurrentCodePaneTabWrapper(remainingTabs[remainingTabs.length - 1]);
        } else {
          setCurrentCodePaneTabWrapper(null);
        }
      }
      setCodePaneTabs(prev => prev.filter(id => id !== tabName));
    } else {
      if (tabName === currentOutputPaneTab) {
        if (remainingTabs.length >= 1) {
          setCurrentOutputPaneTabWrapper(remainingTabs[remainingTabs.length - 1]);
        } else {
          setCurrentOutputPaneTabWrapper('console');
        }
      }
      setOutputPaneTabs(prev => prev.filter(id => id !== tabName));
    }
  };

  // make this a callback with a memo call
  

  

  const shouldShowBroadcastIconLiveCoding = () => {
    // this one is the live coding message
    // logger.log('CodeEditor: SSBI3, lastTeacherTextUpdateTimestamp: ', lastTeacherTextUpdateTimestamp, ', codeData.teacherTextEditFile: ', codeData.teacherTextEditFile);

    if (isLiveCodingMessageHidden) return false;

    if (!lastTeacherTextUpdateTimestampMessage || !codeDataRef.current.teacherTextEditFile) return false;
    const studentName = `student-${name}`;
    if (!(
          codeDataRef.current.teacherTextEditFile.startsWith('teacher-name-') || 
          (codeDataRef.current.teacherTextEditFile.startsWith(studentName) && showFFLiveCodingOnStudentFiles)
        )) return false;
    const elapsedSeconds = (Date.now() - lastTeacherTextUpdateTimestampMessage) / 1000;
    return elapsedSeconds <= secondsToShowBroadcastMessage;
  };


  

  const shouldShowBroadcastIconFile = (fileName: string) => {
    // used for tab broadcast message, check if file matches
    //logger.log('CodeEditor: SSBIF, fileName: ', fileName, ', lastTeacherTextUpdateTimestamp: ', lastTeacherTextUpdateTimestamp, ', codeDataRef.current.teacherTextEditFile: ', codeDataRef.current.teacherTextEditFile);
    if (!lastTeacherTextUpdateTimestamp || !codeDataRef.current.teacherTextEditFile) return false;
    const elapsedSeconds = (Date.now() - lastTeacherTextUpdateTimestamp) / 1000;
    return elapsedSeconds <= secondsToShowBroadcast && fileName === codeDataRef.current.teacherTextEditFile;
  };

  

  const addTabRef = (tabId: string, element: HTMLDivElement | null) => {
    tabRefs.current[tabId] = element;
  };

  const scrollLeft = () => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollLeft -= 100;
    }
  };

  const scrollRight = () => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollLeft += 100;
    }
  };



  // what is this, is this right??
  let inputInProgress = false;
  
  function inputHandler(prompt: string): Promise<string> {
    if (inputInProgress) {
      return Promise.reject(new Error("Another input is already in progress"));
    }
    inputInProgress = true;

    return new Promise<string>((resolve, reject) => {
      // Append the prompt to the output
      appendToOutput(prompt + '\n');

      const outputContainer = document.querySelector('.input-container-container');
      if (!outputContainer) {
        inputInProgress = false;
        reject(new Error("Output container not found"));
        return;
      }

      const inputContainer = document.createElement('div');
      inputContainer.className = 'input-container';
      inputContainer.innerHTML = `
        <input type="text" id="skulpt-input" class="skulpt-input">
      `;
      outputContainer.appendChild(inputContainer);

      const inputElement = inputContainer.querySelector('#skulpt-input') as HTMLInputElement;

      if (!inputElement) {
        inputInProgress = false;
        reject(new Error("Failed to create input element"));
        return;
      }

      inputElement.focus();

      function handleSubmit() {
        const value = inputElement.value;
        cleanup();
        // Append the answer to the output
        appendToOutput(value + '\n');
        resolve(value);
      }

      function handleKeyPress(e: KeyboardEvent) {
        if (e.key === 'Enter') {
          handleSubmit();
        }
      }

      function cleanup() {
        inputElement.removeEventListener('keypress', handleKeyPress);
        outputContainer?.removeChild(inputContainer);
        inputInProgress = false;
      }

      inputElement.addEventListener('keypress', handleKeyPress);

      // Ensure cleanup happens even if the promise is not resolved/rejected
      setTimeout(() => {
        if (inputInProgress) {
          cleanup();
          reject(new Error("Input timeout"));
        }
      }, 300000); // 5 minutes timeout, adjust as needed
    });
  }

  // Helper function to append text to the output
  function appendToOutput(text: string) {
    setOutput(prevOutput => prevOutput + text);
    const textOutput = document.getElementById('modal-text-output');
    if (textOutput) {
      textOutput.innerText += text;
    }
  }

  function clearInputBoxes() {
    const inputContainers = document.querySelectorAll('.input-container');
    inputContainers.forEach(container => {
      if (container.parentNode) {
        container.parentNode.removeChild(container);
      }
    });
    // Reset the inputInProgress flag
    (window as any).inputInProgress = false;
  }

  
  const handleCopyButtonClick = (tabName: string | null) => {
    if (!tabName) { 
      logger.error('trying to copy file from null tab, this should never happen');
      return;
    }
    // !!!!!!!!!!!!! only handle code pane
    const [...parts] = tabName.split('-');
    const fileName = parts.pop()?? '';
    const text = codeDataRef.current.teacherFiles?.[fileName].text ?? '';
    const studentTabName = 'student-' + name + '-' + fileName;
    codeDataRef.current.studentFiles = {
      ...codeDataRef.current.studentFiles,
      [name]: {
        ...codeDataRef.current.studentFiles?.[name],
        [fileName]: {
          text: text,
          newFile: true,
        },
      },
    };
    onCreateFile(false, name, fileName, text);
    setCurrentCodePaneTabWrapper(studentTabName);
    // setFileAddedRemoved(true);  // what should be done with this???
  }



  const handleContentUrlChange = (fileName: string, newUrl: string) => {
    // is this even right??
    updateFiles(fileName, newUrl, 'teacher', false);
  };





  const runCode = (mainPane: boolean) => {
    logger.log('RunCode called')
    
    const currentTab = mainPane ? currentCodePaneTab : currentOutputPaneTab;
    if (currentTab === null || codeDataRef.current?.studentFiles === null || codeDataRef.current?.teacherFiles === null) { 
      logger.error('RunCode: No file selected');
      return; 
    }
    clearInputBoxes();

    logger.log('RunCode called 2')

    const [fileType, ...rest] = currentTab.split('-');
    const fileName = rest.pop() ?? '';
    const studentName = rest.join('-');
    let code = '';

    const teacherFiles = codeDataRef.current?.teacherFiles === undefined ? {} : codeDataRef.current?.teacherFiles;
    const studentFiles = codeDataRef.current?.studentFiles === undefined ? {} : codeDataRef.current?.studentFiles;

    logger.log('RunCode called 3')

    if (fileType === 'student') {
      if (codeDataRef.current?.studentFiles === null) {
        logger.error('RunCode: No studentFiles found');
        return;
      } 
      code = studentFiles[studentName][fileName]['text'];
    } else if (fileType === 'teacher') {
      if (codeDataRef.current?.teacherFiles === null) {
        logger.error('RunCode: No teacherFiles found');
        return;
      }
      code = teacherFiles[fileName].text;
    } else {
      logger.error('RunCode: unknown fileType: ', fileType);
      return;
    }

    logger.log('RunCode called 4')
    
    setOutput(''); // Clear previous output

    logger.log('Running code:', code);

    // Function to redirect console.log to your custom output
    const customLog = (...args: any[]) => {
      const output = args.map(arg => 
        typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
      ).join(' ');
      setOutput(prev => prev + output + '\n');
    };

    if (fileName.endsWith('.js')) {
      logger.log('running js code');
      // JavaScript or p5.js code
      const isP5 = code.includes('function setup()') || code.includes('function draw()');
      logger.log('running isP5:', isP5);
      setIsRunningP5(isP5);

      // Override console.log
      const originalConsoleLog = console.log;
      console.log = customLog;
  
      if (isP5) {
        //logger.log('running p5.js code');
        // Run p5.js code
        try {
          setP5SketchCode(code);
          setRunKey(prevKey => prevKey + 1);
          //logger.log('p5.js code ran successfully');
        } catch (err: any) {
          logger.error('p5.js code error:', err);
          setOutput(output => output + '\n' + err.toString());
        }
      } else {
        logger.log('running regular js code');
        // Run regular JavaScript code
        try {
          // Create a new Function to run the code in a sandboxed environment
          const runJS = new Function(code);
          runJS();
          logger.log('JavaScript code ran successfully');
        } catch (err: any) {
          logger.error('JavaScript code error:', err);
          setOutput(output => output + '\n' + err.toString());
        }
      }
      // Restore original console.log
      console.log = originalConsoleLog;

      setCurrentOutputPaneTabWrapper('console');
    } else {
       // Python code
      setIsRunningP5(false);
      setCurrentOutputPaneTabWrapper('console');

      // there is an issue with the main_canvas having multiple event listeners for key presses, tried to fix but it didn't work
      const mainCanvas = (window as any).Sk.main_canvas;
      if (mainCanvas && mainCanvas.parentNode) {
        mainCanvas.parentNode.removeChild(mainCanvas);
        (window as any).Sk.main_canvas = null;
      }
      (window as any).Sk.main_canvas = document.createElement("canvas");
      
      const turtleDiv = document.getElementById('turtle-canvas-pane');
      if (turtleDiv) {
        turtleDiv.innerHTML = ''; // Clear previous turtle graphics
      } 

      //Set up Sk.read to read from our virtual file system
      (window as any).Sk.read = function(filename: string, fileType: string, importFileType: string) {
        logger.log('Sk.read called for filename:', filename, ', fileType:', fileType, ', importFileType:', importFileType);


        if (!importFileType) {
          // First, check if it's a built-in module, only do this if fileType is not specified
          const builtinPath = 'src/builtin/' + filename;
          logger.log('Checking for import in builtinFiles:', (window as any).Sk.builtinFiles);
          logger.log('Checking for builtinPath: ', builtinPath);
          if ((window as any).Sk.builtinFiles && (window as any).Sk.builtinFiles.files[builtinPath]) {
            logger.log('Built-in file found:', builtinPath); 
            return (window as any).Sk.builtinFiles.files[builtinPath];
          }
          const libPath = 'src/lib/' + filename;
          logger.log('Checking for libPath: ', libPath);
          if ((window as any).Sk.builtinFiles && (window as any).Sk.builtinFiles.files[libPath]) {
            logger.log('libPath file found:', libPath); 
            return (window as any).Sk.builtinFiles.files[libPath];
          }
        }

        // to note: there is an edge case say a student file has: from file1 import my_fun
        // if the student file1.py doesnt have my_fun but the teacher file1.py does, it try to import from the student file and fail
        // there is no check to see if the function exists only if the file exists
        // the only work around is a manual check see below. 

        // const checkImport = (fileContent: string, importName: string) => {
        //   return fileContent.includes(`def ${importName}`) || fileContent.includes(`class ${importName}`);
        // };

        // This however has issues:
        // say if you have print('def my_fun():') or # def my_fun(): in the student file1.py it will be a false positive
        // if you have def  my_fun(): it will be a false negative
        // I have left this for now, if you really want to import outside of your class/student scope you can use student__ or class__ prefixes

        // there is also a potential soln by modifying the (window as any).Sk.builtin.__import__, you would copy the original import as a const var
        // then modify it to call the original to get the module and then check if the module has the function or class as an attribute
        // not 100% sure if or how this would work, maybe possible but really complicated, good luck trying that!
          
        // If not a built-in, check our virtual file system
        let moduleName = filename.endsWith('.py') ? filename.slice(0, -3) : filename;
        
        const teacherFiles = codeDataRef.current?.teacherFiles;
        const studentFiles = codeDataRef.current?.studentFiles?.[studentName];

        if (importFileType === 'class') {
          // specifically looking to import a class file
          if (teacherFiles && teacherFiles[moduleName + '.py']) {
            return teacherFiles[moduleName + '.py'].text;
          }

        } else if (importFileType === 'student') {
          // specifically looking to import a student file
          if (studentFiles && studentFiles[moduleName + '.py']) {
            return studentFiles[moduleName + '.py'].text;
          }

        } else {
          // importFileType not specified, check fileType first and then then other files
          if (fileType === 'teacher') {
            if (teacherFiles && teacherFiles[moduleName + '.py']) {
              return teacherFiles[moduleName + '.py'].text;
            } else if (studentFiles && studentFiles[moduleName + '.py']) {
              return studentFiles[moduleName + '.py'].text;
            }
          } else if (fileType === 'student') {
            if (studentFiles && studentFiles[moduleName + '.py']) {
              return studentFiles[moduleName + '.py'].text;
            } else if (teacherFiles && teacherFiles[moduleName + '.py']) {
              return teacherFiles[moduleName + '.py'].text;
            }
          }

        }
        
        throw new Error("File not found: '" + filename + "'");
      };

      //Modify Sk.importSearchPathForName to work with our virtual file system
      (window as any).Sk.importSearchPathForName = function (name: string, ext: string, searchPath?: any) {
        logger.log('Sk.importSearchPathForName called for:', name, ext, searchPath);
        if (name === 'student') {
          return undefined;
        }

        let prefix = '';
        let actualName = name;
        
        // Check for our custom prefix using double underscores
        if (name.startsWith('student__') || name.startsWith('class__')) {
          const parts = name.split('__');
          prefix = parts[0];
          actualName = parts.slice(1).join('__');  // In case there are more double underscores
        }
        
        const filename = actualName + ext;

        logger.log('Sk.importSearchPathForName: actualName:', actualName, ', filename:', filename, ', prefix:', prefix);

        try {
          const code = (window as any).Sk.read(filename, fileType, prefix);
          return (window as any).Sk.misceval.chain(code, function(code: string) {
              if (code !== undefined) {
                  return {filename: filename, code: code, packagePath: false};
              }
          });
        } catch (e) {
            logger.warn('Sk.importSearchPathForName: File not found: ', filename);
            return undefined;
        }
      };

      (window as any).Sk.configure({
        output: function(text: string) {
          setOutput(output => output + text);
          const textOutput = document.getElementById('modal-text-output');
          if (textOutput) {
            textOutput.innerText += text; // Update text output live
          }
        },
        read: (window as any).Sk.read,
        // read: (x: any) => {
        //   if ((window as any).Sk.builtinFiles === undefined || (window as any).Sk.builtinFiles.files[x] === undefined) {
        //     throw new Error("File not found: '" + x + "'");
        //   }  
        //   return (window as any).Sk.builtinFiles.files[x];
        // },
        inputfun: inputHandler,
        inputfunTakesPrompt: true,
        autoFlush: true,
      });

    

      (window as any).Sk.quitHandler = () => {
        logger.log('quitHandler called');
        
        const modal = document.querySelector(".modal");
        if (modal) {
          (modal as HTMLElement).style.display = "none";
        }
        (window as any).Sk.eventQueue = [];
      };

      // Set the output target based on useModal and the type of code
      const outputTarget = 'turtle-canvas-pane';

      ((window as any).Sk.TurtleGraphics || ((window as any).Sk.TurtleGraphics = {})).target = outputTarget;

      // Run the code using Skulpt
      (window as any).Sk.misceval.asyncToPromise(() => {
        return (window as any).Sk.importMainWithBody("<stdin>", false, code, true);
      })
      .then(() => {
        logger.log('CodeEditor for ', name, ': code ran successfully');
      })
      .catch((err: Error) => {
        logger.log('CodeEditor for ', name, ': code error');
        setOutput(output => output + '\n' + err.toString());
      });
    }
  };

  

  const handleSizeChange = (newSizes: number[]) => {
    // Convert pixel values to percentages
    const totalSize = newSizes.reduce((sum, size) => sum + size, 0);
    const percentages = newSizes.map(size => (size / totalSize) * 100);

    let [filesPaneSize, mainPaneSize, outputPaneSize] = percentages;

    // Lock the filesPane if its too small
    if (filesPaneSize < 10) {
      filesPaneSize = 0;
    } else if (filesPaneSize >= 10 && filesPaneSize < 15) {
      filesPaneSize = 15;
    } else if (filesPaneSize > 25) {
      filesPaneSize = 25;
    }

    // Lock the outputPane if its too small
    if (outputPaneSize < 10) {
      outputPaneSize = 0;
    } else if (outputPaneSize >= 10 && outputPaneSize < 15) {
      outputPaneSize = 15;
    } else if (outputPaneSize > 75) {
      outputPaneSize = 75;
    }
    
    // Adjust the main pane size to absorb changes in the files pane
    mainPaneSize = 100 - filesPaneSize - outputPaneSize;

    // Ensure main pane doesn't become too small
    if (mainPaneSize < 25) {
      mainPaneSize = 25;
      outputPaneSize = 100 - mainPaneSize - filesPaneSize;
    }

    // Round to two decimal places
    const newSizePercentages = [
      filesPaneSize.toFixed(2),
      mainPaneSize.toFixed(2),
      outputPaneSize.toFixed(2)
    ].map(size => `${size}%`);

    setSizes(newSizePercentages);
    if (filesPaneSize == 0) {
      setIsFilePaneCollapsed(true);
    } else if (filesPaneSize > 0) {
      setIsFilePaneCollapsed(false);
    }

    logger.log('New sizes:', newSizePercentages);
  };
   
  
  
  const updateFiles = (
    fileName: string, text: string, fileType: 'student' | 'teacher', isCodeFile: boolean,
    caretPosition?: { line: number; col: number }, highlightedRange?: { anchor: { line: number; col: number }; head: { line: number; col: number } },
    studentName?: string
  ) => {
    performance.mark('updateFiles-start');
    logger.log('CodeEditor: updateFiles fileName: ', fileName);


    let cpLine = 1;
    let cpCol = 1;
    let hrAnchorLine = 1;
    let hrAnchorCol = 1;
    let hrHeadLine = 1;
    let hrHeadCol = 1;

    if (isCodeFile) {
      cpLine = caretPosition?.line ? caretPosition?.line : codeDataRef.current.userInfo[name].caretPosition.line;
      cpCol = caretPosition?.col ? caretPosition?.col : codeDataRef.current.userInfo[name].caretPosition.col;
      hrAnchorLine = highlightedRange?.anchor.line ? highlightedRange?.anchor.line : codeDataRef.current.userInfo[name].highlightedRange.anchor.line;
      hrAnchorCol = highlightedRange?.anchor.col ? highlightedRange?.anchor.col : codeDataRef.current.userInfo[name].highlightedRange.anchor.col;
      hrHeadLine = highlightedRange?.head.line ? highlightedRange?.head.line : codeDataRef.current.userInfo[name].highlightedRange.head.line;
      hrHeadCol = highlightedRange?.head.col ? highlightedRange?.head.col : codeDataRef.current.userInfo[name].highlightedRange.head.col;
    } 

    // record that the file has been changed, used for restore and undo
    // I think we can do the increment of a ref and then check if its state has changed and the button changes we can rerender
    // incrementFileChanges(fileName); dont do this for now, lets see if we can avoid a codeEditor rerender, put this back later

    const origin = isTeacher ? 'teacher' : 'student';
    // setFileAddedRemoved(true);

    // update the ref
    codeDataRef.current = {
      ...codeDataRef.current,
      changeType: 'selfUpdate',
      userInfo: {
        ...codeDataRef.current.userInfo,
        [name]: {
          userType: origin,
          caretPosition: { line: cpLine, col: cpCol },
          highlightedRange: { anchor: { line: hrAnchorLine, col: hrAnchorCol }, head: { line: hrHeadLine, col: hrHeadCol } },
          activeFile: `${fileType}-${fileType === 'teacher' ? 'name' : (isTeacher ? studentName : name)}-${fileName}`,
        },
      },
      version: codeDataRef.current.version + 1,
    };

    if (fileType === 'student') {
      if (codeDataRef.current.studentFiles !== null) {
        codeDataRef.current.studentFiles = {
          ...codeDataRef.current.studentFiles,
          [studentName ?? '']: {
            ...codeDataRef.current.studentFiles[studentName ?? ''],
            [fileName]: {
              ...(codeDataRef.current.studentFiles[studentName ?? '']?.[fileName] || {}),
              text: text,
            }
          },
        };
      } else {
        codeDataRef.current.studentFiles = {
          [studentName ?? '']: {
            [fileName]: {
              text: text,
              newFile: false,  // this seems strange, I dont think it should ever get here
            }
          },
        };
      }
    } else {
      if (codeDataRef.current.teacherFiles !== null) {
        codeDataRef.current.teacherFiles = {
          ...codeDataRef.current.teacherFiles,
          [fileName]: {
            ...codeDataRef.current.teacherFiles[fileName],
            text: text,
          }
        };
      } else {
        codeDataRef.current.teacherFiles = {
          [fileName]: {
            text: text,
            hidden: false,  // this seems strange, I dont think it should ever get here
            classOnly: false, // this seems strange, I dont think it should ever get here
          }
        };
      }
    }

    codeDataRef.current.version = codeDataRef.current.version + 1;

    const relevantName = fileType === 'teacher' ? name : (isTeacher ? studentName?? 'Error' : name);
    onUpdateFile(fileType === 'teacher', relevantName, fileName, text); 
  
    performance.mark('updateFiles-end');
  };

 

  


  const addTabFromFileName = useCallback((fileName: string, fileType: 'student' | 'teacher', studentName?: string) => {
    logger.log('addTabFromFileName: fileName: ', fileName);
    const userType = isTeacher ? 'teacher' : 'student';
    const extension = fileName.split('.').pop() || '';
    const tabName = fileType + '-' + (fileType === 'teacher' ? 'name' : (isTeacher ? studentName : name)) + '-' + fileName;
    const shouldBeInOutputPane = outputPaneRules[userType][fileType].includes(extension);

    addTabFromTabNameCoreFunction(tabName, shouldBeInOutputPane);
  }, [outputPaneRules, outputPaneTabs, codePaneTabs, isTeacher, name]);  // should I include the set state functions, dont need to but might give build warnings

  const addTabFromTabName = (tabName: string) => {
    const extension = tabName.split('.').pop() || '';
    const userType = isTeacher ? 'teacher' : 'student';
    const fileType = getFileTypeFromTabName(tabName);
    if (fileType !== 'student' && fileType!== 'teacher') {
      logger.error('addTabFromTabName: invalid fileType: ', fileType);
      return;
    }
    const shouldBeInOutputPane = outputPaneRules[userType][fileType].includes(extension);

    addTabFromTabNameCoreFunction(tabName, shouldBeInOutputPane);
  };

  const addTabFromTabNameCoreFunction = (tabName: string, shouldBeInOutputPane: boolean ) => {
    if (codePaneTabs.includes(tabName)) {
      logger.log('addTabFromTabNameCoreFunction: tabName already in codePaneTabs: ', tabName);
      if (tabName !== currentCodePaneTab) {
        setCurrentCodePaneTabWrapper(tabName);
      }
      return;
    }
    if (outputPaneTabs.includes(tabName)) {
      logger.log('addTabFromTabNameCoreFunction: tabName already in outputPaneTabs: ', tabName);
      if (tabName !== currentOutputPaneTab) {
        setCurrentOutputPaneTabWrapper(tabName);
      }
      return;
    }
    if (shouldBeInOutputPane) {
      logger.log('addTab: adding tab ', tabName, ' to output pane, outputPaneTabs: ', outputPaneTabs);
      // logger.log('addTab: (tabName in outputPaneTabs): ', (tabName in outputPaneTabs));
      setCurrentOutputPaneTabWrapper(tabName);
      if (!(outputPaneTabs.includes(tabName))) {
        setOutputPaneTabs(prev => [...prev, tabName]);
      }
    } else {
      logger.log('addTab: adding tab ', tabName, ' to code pane, codePaneTabs: ', codePaneTabs);
      // logger.log('addTab: (tabName in codePaneTabs): ', (tabName in codePaneTabs));
      setCurrentCodePaneTabWrapper(tabName);
      if (!(codePaneTabs.includes(tabName))) {
        setCodePaneTabs(prev => [...prev, tabName]);
      }
    } 
  }

  

  //   this code was inside a useEffect if currentFile changed, need to move this into DraggableTab component

  //   // Direct manipulation of scroll position
  //   const currentTabRef = tabRefs.current[currentCodePaneTab];
  //   const container = tabContainerRef.current;
    
  //   if (currentTabRef && container) {
  //     const containerRect = container.getBoundingClientRect();
  //     const tabRect = currentTabRef.getBoundingClientRect();

  //     // Calculate new scroll position
  //     const scrollLeft = container.scrollLeft + (tabRect.left - containerRect.left - (containerRect.width / 2) + (tabRect.width / 2));

  //     // Set the scroll position
  //     container.scrollLeft = scrollLeft;
  //   }


  const setCurrentCodePaneTabWrapper = useCallback((tabName: string | null) => {
    // wrapper function that does some checks, sets the active file, resets fileChanges, then sets state variable
    logger.log('setCurrentCodePaneTabWrapper: tabName: ', tabName);
    // updateActiveFile(tabName);  // do we really need to do this, just update the current file and userInfo when you start typing
    initializeFileChanges(tabName);
    setCurrentCodePaneTab(tabName);
  }, [isTeacher, name]);

  const setCurrentOutputPaneTabWrapper = useCallback((tabName: string) => {
    // wrapper function that does some checks, sets the active file, resets fileChanges, then sets state variable
    logger.log('setCurrentOutputPaneTabWrapper: tabName: ', tabName);
    if (tabName !== 'console') {
      // updateActiveFile(tabName);
      initializeFileChanges(tabName);
    }
    setCurrentOutputPaneTab(tabName);
  }, [isTeacher, name]);

  const updateActiveFile = (tabName: string | null) => {
    const origin = isTeacher ? 'teacher' : 'student';
    // Directly modify codeDataRef.current to update activeFile
    codeDataRef.current = {
      ...codeDataRef.current,
      changeType: 'currentFile',
      userInfo: {
        ...codeDataRef.current.userInfo,
        [name]: {
          ...codeDataRef.current.userInfo[name],
          caretPosition: { line: 1, col: 1 },
          highlightedRange: { anchor: { line: 1, col: 1 }, head: { line: 1, col: 1 } },
          activeFile: tabName,
          userType: origin,
        },
      },
    };
  };

  const initializeFileChanges = (tabName: string | null) => {
    if (tabName !== null) {
      const fileName = tabName?.split('-')[2];
      if (!fileChangesRef.current[fileName]) {
        fileChangesRef.current = {
          ...fileChangesRef.current,
          [fileName]: 0,
        };
      }
    }
  }

  
  
  const deleteFile = useCallback((fileName: string, fileType: 'student' | 'teacher', studentName?: string) => {
    if (codeDataRef.current.studentFiles === null && fileType === 'student') {
      logger.error('Trying to remove student file but studentFiles is null');
      return;
    }
    if (codeDataRef.current.teacherFiles === null && fileType === 'teacher') {
      logger.error('Trying to remove student file but teacherFiles is null');
      return;
    }
    logger.log('CE deleteFile, fileName: ', fileName, ', fileType: ', fileType, ', studentName: ', studentName);
    const confirmDelete = window.confirm(`Are you sure you want to delete the file "${fileName}"?`);
    if (confirmDelete) {
      // Update codeDataRef.current directly, is this change type correct?
      codeDataRef.current = {
        ...codeDataRef.current,
        changeType: 'currentFile',
      };

      if (fileType === 'student') {
        if (codeDataRef.current.studentFiles === null) {
          logger.error('Trying to remove student file but codeDataRef.current.studentFiles is null');
          return;
        }
        const updatedFiles = { ...codeDataRef.current.studentFiles[studentName ?? ''] };
        delete updatedFiles[fileName];
        codeDataRef.current.studentFiles = {
          ...codeDataRef.current.studentFiles,
          [studentName ?? '']: updatedFiles,
        };
      } else {
        if (codeDataRef.current.teacherFiles === null) {
          logger.error('Trying to remove teacher file but codeDataRef.current.teacherFiles is null');
          return;
        }
        const updatedFiles = { ...codeDataRef.current.teacherFiles };
        delete updatedFiles[fileName];
        codeDataRef.current.teacherFiles = updatedFiles;
      }
      


      const usedStudentName = studentName?.replace(/-/g, '_') ?? 'blank';
      const tabName = (fileType === 'student') ? `student-${usedStudentName}-${fileName}` : `teacher-name-${fileName}`;
      logger.log('tabName: ', tabName, ', codePaneTabs: ', codePaneTabs, ', outputPaneTabs: ', outputPaneTabs);

      if (codePaneTabs.includes(tabName)) {
        removeTab(tabName, 'codePane');
      } else if (outputPaneTabs.includes(tabName)) {
        removeTab(tabName, 'outputPane');
      } 
      setFileAddedRemoved(true);
      const isTeacherFile = (fileType === 'teacher');
      const relevantName = isTeacher ? (isTeacherFile ? name : studentName?? 'Error') : name;
      onDeleteFile(isTeacherFile, relevantName, fileName);  // we delete in codeData but not sure if BE delete is succesful this could be an issue
    }
  }, [isTeacher, name, codePaneTabs, outputPaneTabs]);  // do I include set state functions in dependencies ??

  // this will rerender the FilesPane if codePaneTabs or outputPaneTabs changes, not ideal but not sure how to avoid it
  const activateTab = useCallback((tabName: string) => {
    logger.log('activateTab:', tabName);
    if (tabName in codePaneTabs) {
      setCurrentCodePaneTabWrapper(tabName);
    } else if (tabName in outputPaneTabs) {
      setCurrentOutputPaneTabWrapper(tabName);
    }
  }, [codePaneTabs, outputPaneTabs]);



  const toggleCollapse = useCallback(() => {
    setIsTransitioning(true);
    logger.log('CE: toggleCollapse, sizes:', sizes);
    const [filePaneSize, mainPaneSize, outputPaneSize] = sizes.map(size => 
      parseFloat(size.replace('%', '')) / 100
    );
    
    if (isFilePaneCollapsed) {
      const newFilePaneSize = 0.15;
      const newOutputPaneSize = outputPaneSize;
      const newMainPaneSize = 1 - newFilePaneSize - newOutputPaneSize;

      const newSizes = [
        `${newFilePaneSize * 100}%`,
        `${newMainPaneSize * 100}%`,
        `${newOutputPaneSize * 100}%`
      ];
      logger.log('CE: toggleCollapse isFilePaneCollapsed:', isFilePaneCollapsed, ', newSizes: ', newSizes);
      setSizes(newSizes);

    } else {
      const newFilePaneSize = 0;
      const newOutputPaneSize = outputPaneSize;
      const newMainPaneSize = 1 - newFilePaneSize - newOutputPaneSize;
      
      const newSizes = [
        `${newFilePaneSize * 100}%`,
        `${newMainPaneSize * 100}%`,
        `${newOutputPaneSize * 100}%`
      ];
      logger.log('CE: toggleCollapse isFilePaneCollapsed:', isFilePaneCollapsed, ', newSizes: ', newSizes);
      setSizes(newSizes);
    }
    setIsFilePaneCollapsed(prev => !prev);
    setTimeout(() => setIsTransitioning(false), 300); // Match this with your transition duration
  }, [sizes, isFilePaneCollapsed]);


  if (codeDataRef.current.studentConnections === null || codeDataRef.current.teacherConnections === null || 
    codeDataRef.current.studentFiles === null ||codeDataRef.current.teacherFiles === null) {
    return <div>Loading...</div>;
  } else {

  return (
    <div className="editor-row" style={{ position: 'relative' }}>
      <CollapseButton 
        isCollapsed={isFilePaneCollapsed} 
        onClick={toggleCollapse} 
        sizes={sizes}
        isTransitioning={isTransitioning}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <SplitPane
          split="vertical"
          sizes={sizes}
          onChange={handleSizeChange}
          sashRender={(_, active) => <SashContent active={active} type="vscode"></SashContent>}
        >
          <Pane minSize="0%" maxSize="100%" className={`${isTransitioning ? 'with-transitions' : ''}`}>
            <FilesPane 
              codeDataRef={codeDataRef}
              isTeacher={isTeacher}
              name={name}
              course={course}
              lesson={lesson}
              permissionedFiles={permissionedFiles}
              structureVersion={structureVersion}
              lastTeacherTextUpdateTimestamp={lastTeacherTextUpdateTimestamp}

              onSwitchLesson={switchLesson}  
              onCreateLesson={onCreateLesson}  
              onUpdateLessonName={onUpdateLessonName} 
              onUpdateLessonState={onUpdateLessonState}  
              onDeleteLesson={onDeleteLesson} 

              onCreateFile={createFile} 
              onDeleteFile={deleteFile} 
              onUpdateFileName={updateFileName}  
              onUpdateFilePermission={onUpdateFilePermission} 
              onUpdateFileHidden={onUpdateFileHidden}  
              onUpdateFileClassOnly={onUpdateFileClassOnly} 
  
              onAddTab={addTabFromFileName}  
              onSetCurrentFile={activateTab}  
            />
          </Pane>
          <Pane minSize="0%" maxSize="100%" className={`${isTransitioning ? 'with-transitions' : ''}`}>
            <div className="code-editor-container">
              <Tab.Container 
                id="file-tabs" 
                activeKey={currentCodePaneTab || undefined} 
                onSelect={(k) => k && setCurrentCodePaneTabWrapper(k)}
              >
                <div className="tab-header">
                  <div className="tabs-wrapper" ref={tabContainerRef as React.RefObject<HTMLDivElement>}>
                    <div className="tabs-container main">
                      <DraggableTabs
                        paneId="codePane"
                        tabs={codePaneTabs}
                        isTeacher={isTeacher}
                        currentTab={currentCodePaneTab}
                        isTemplate={isTemplate}
                        onRemoveTab={(tabId) => removeTab(tabId, 'codePane')}
                        onAddTabRef={addTabRef}
                        onShouldShowBroadcastIconFile={shouldShowBroadcastIconFile}
                        onRunCode={() => { runCode(true); }}
                        onRevertChanges={() => { logger.log('code tabs onRevert called'); }}
                        onRemoveFile={deleteFile}
                      />
                    </div>
                  </div>
                  <div className="tab-nav-icons">
                    {showNavButtons && (
                      <>
                        <FaChevronLeft className="tab-nav-icon" onClick={scrollLeft} />
                        <FaChevronRight className="tab-nav-icon" onClick={scrollRight} />
                      </>
                    )}
                  </div>
                </div>
                <Droppable droppableId="editor-content" direction="vertical">
                  {(provided) => (
                    <div 
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="editor-content"
                    >
                      {currentCodePaneTab ? (
                        <>
                          {showFFTextHighlightOptions && (
                            <TextHighlightOptions
                              userType={currentCodePaneTab.startsWith('student') ? 'student' : 'teacher'}
                              selectedOption={highlightOption}
                              onOptionSelect={setHighlightOption}
                            />
                          )}
                          {currentCodePaneTab && isTabCode(currentCodePaneTab) && shouldShowCopyButton(currentCodePaneTab) && (
                            <div className="copy-to-projects-container ctpc-main">
                              <button className="btn btn-purple copy-to-projects-button" onClick={() => handleCopyButtonClick(currentCodePaneTab)}>
                                <BsDownload className="download-icon" />
                                Copy to Projects
                              </button>
                            </div>
                          )}
                          {showFFEditorUserIndicator && currentCodePaneTab && (
                            <div className="user-indicator-container">
                              <UserIndicator codeData={codeDataRef.current} currentFile={currentCodePaneTab} />
                            </div>
                          )}
                          {!isTeacher && currentCodePaneTab.startsWith('student') && (
                            <div className="student-submit-restore-section files-pane-font">
                              {showFFSubmitButton && (
                                <button className="btn btn-primary button-right-margin" onClick={handleSubmit}>
                                  <BsClipboard2CheckFill className="submit-button-icon" />
                                  Submit
                                </button>
                              )}
                              {showFFRestoreButton && hasOriginalFile(true) && hasEnoughChangesToRestore() && (
                                <button className="btn btn-secondary button-right-margin" onClick={handleRestore}>
                                  <MdOutlineRestore className="submit-button-icon" />
                                  Restore original
                                </button>
                              )}
                              {showUndoButton && (
                                <button className="btn btn-warning" onClick={handleUndo}>
                                  <MdUndo className="submit-button-icon" />
                                  Undo Restore
                                </button>
                              )}
                            </div>
                          )}
                          {showSubmitModal && (
                            <div className="message-box">
                              <div className="message-box-content">
                                <p className="files-pane-font bold-font">Submit file?</p>
                                <p className="files-pane-font">The file will then be marked.</p>
                                <div className="message-box-buttons">
                                  <button className="btn btn-secondary button-right-margin" onClick={handleSubmitCancel}>
                                    Cancel
                                  </button>
                                  <button className="btn btn-primary" onClick={handleSubmitConfirm}>
                                    <BsClipboard2CheckFill className="submit-button-icon" />
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          {showRestoreModal && (
                            <div className="message-box">
                              <div className="message-box-content">
                                <p className="files-pane-font bold-font">Delete any changes?</p>
                                <p className="files-pane-font">This will restore the original file.</p>
                                <div className="message-box-buttons">
                                  <button className="btn btn-success button-right-margin" onClick={handleRestoreCancel}>
                                    Keep
                                  </button>
                                  <button className="btn btn-danger" onClick={() => { handleRestoreConfirm(true); }}>
                                    <BsTrashFill className="submit-button-icon"/>
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          {shouldShowBroadcastIconLiveCoding() && (() => {
                            const isTeacherFile = codeDataRef.current.teacherTextEditFile?.startsWith('teacher-name-')
                            
                            // if we are getting a file name in tab format, then we should say TextEditTab
                            // also no need to pull out file and then recreate tab name, make two functions:
                            // addTabFromFileName and addTabFromTabName
                            const studentFileStart = `student-${name}-`
                            const liveCodingFileName = (isTeacherFile ? 
                              codeDataRef.current.teacherTextEditFile?.replace('teacher-name-', '') :
                              codeDataRef.current.teacherTextEditFile?.replace(studentFileStart, ''))?? '';
                            const codePaneFileName = getFileNameFromTabName(currentCodePaneTab?? '');
                            const outputPaneFileName = getFileNameFromTabName(currentCodePaneTab?? '');

                            const isActiveTab = liveCodingFileName === codePaneFileName || liveCodingFileName === outputPaneFileName;
                            

                            const handleClick = () => {
                              addTabFromFileName(liveCodingFileName, 'teacher');
                            }
                            
                            return (
                              <div className="live-coding-message">
                                <div className="live-coding-initials-circle teacher">{getInitials(codeDataRef.current.teacherTextEditName?? "")}</div>
                                {codeDataRef.current.teacherTextEditName} is live coding 
                                {codeDataRef.current.teacherTextEditFile && !isActiveTab && (
                                  <span className="live-coding-file" onClick={handleClick}>
                                    <button className="btn btn-primary live-coding-file-button">
                                      {liveCodingFileName}
                                    </button>
                                  </span>
                                )}
                                <button className="close-button" onClick={hideLiveCodingMessage}>
                                  &times;
                                </button>
                              </div>
                            )
                          })()}
                          <Tab.Content>
                            <TabContent
                              codeDataRef={codeDataRef}
                              tabs={codePaneTabs}
                              isTeacher={isTeacher}
                              paneType="codePane"
                              lastTypingTimestamps={lastTypingTimestamps}
                              name={name}
                              permissionedFiles={permissionedFiles}
                              highlightOption={highlightOption}
                              isRunningP5={isRunningP5}
                              p5SketchCode={p5SketchCode}
                              runKey={runKey}
                              output={output}
                              isFullScreenSlides={isFullScreenSlides}
                              isFullScreenQuiz={isFullScreenQuiz}
                              onUpdateFiles={updateFiles}
                              getNamesTyping={getNamesTyping}
                              toggleFullScreenSlides={toggleFullScreenSlides}
                              toggleFullScreenQuiz={toggleFullScreenQuiz}
                              handleContentUrlChange={handleContentUrlChange}
                            />
                          </Tab.Content>
                        </>
                      ) : (
                        <div className="no-files-placeholder">
                          <p>No files open. Create a new file or open an existing one.</p>
                        </div>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Tab.Container>
            </div>
          </Pane>
          <Pane minSize='0%' maxSize='100%'>
            <div className="output-pane">
              <Tab.Container 
                id="output-tabs" 
                activeKey={currentOutputPaneTab || undefined } 
                onSelect={(key) => setCurrentOutputPaneTabWrapper(key ?? 'console')}
                defaultActiveKey={'console'}
              >
                <div className='tab-section justify-content-between'>
                  <div className="tabs-container output">
                    <DraggableTabs
                      paneId="outputPane"
                      tabs={outputPaneTabs}
                      isTeacher={isTeacher}
                      currentTab={currentOutputPaneTab}
                      isTemplate={isTemplate}
                      onRemoveTab={(tabId) => removeTab(tabId, 'outputPane')}
                      onAddTabRef={addTabRef}
                      onShouldShowBroadcastIconFile={shouldShowBroadcastIconFile}
                      onRunCode={() => { runCode(false); }}
                      onRevertChanges={() => { logger.log('output tabs onRevert called'); }}
                      onRemoveFile={deleteFile}
                    />
                  </div>
                </div>
                <Droppable droppableId="output-section" direction="vertical">
                  {(provided) => (
                    <div 
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="output-section"
                    >
                      {currentOutputPaneTab && isTabCode(currentOutputPaneTab) && shouldShowCopyButton(currentOutputPaneTab) && (
                        <div className="copy-to-projects-container ctpc-output">
                          <button className="btn btn-purple copy-to-projects-button" onClick={() => handleCopyButtonClick(currentOutputPaneTab)}>
                            <BsDownload className="download-icon" />
                            Copy to Projects
                          </button>
                        </div>
                      )}
                      <div className="scrolling-wrapper custom-scrollbar">
                        <Tab.Content className="output-content">
                          <TabContent
                            codeDataRef={codeDataRef}
                            tabs={outputPaneTabs}
                            isTeacher={isTeacher}
                            paneType="outputPane"
                            lastTypingTimestamps={lastTypingTimestamps}
                            name={name}
                            permissionedFiles={permissionedFiles}
                            highlightOption={highlightOption}
                            isRunningP5={isRunningP5}
                            p5SketchCode={p5SketchCode}
                            runKey={runKey}
                            output={output}
                            isFullScreenSlides={isFullScreenSlides}
                            isFullScreenQuiz={isFullScreenQuiz}
                            onUpdateFiles={updateFiles}
                            getNamesTyping={getNamesTyping}
                            toggleFullScreenSlides={toggleFullScreenSlides}
                            toggleFullScreenQuiz={toggleFullScreenQuiz}
                            handleContentUrlChange={handleContentUrlChange}
                          />
                        </Tab.Content>
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Tab.Container>
            </div>
          </Pane>
        </SplitPane>
      </DragDropContext>
    </div>
  );
  }
};

export default CodeEditor;
