type LogLevel = 'none' | 'error' | 'warn' | 'performance' | 'info' | 'debug' | 'all';

interface LoggerConfig {
  level: LogLevel;
  isProd: boolean;
}

class Logger {
  private config: LoggerConfig;

  constructor() {
    const reactAppEnv = process.env.REACT_APP_ENV;
    const isProd = reactAppEnv === 'production';

    this.config = {
      level: isProd ? 'warn' : 'all',
      isProd: isProd
    };
  }

  setConfig(config: Partial<LoggerConfig>) {
    this.config = { ...this.config, ...config };
  }

  private shouldLog(level: LogLevel): boolean {
    const levels: LogLevel[] = ['none', 'error', 'warn', 'info', 'debug', 'all', 'performance'];
    return levels.indexOf(this.config.level) >= levels.indexOf(level);
  }

  error(...args: any[]) {
    if (this.shouldLog('error')) console.error(...args);
  }

  warn(...args: any[]) {
    if (this.shouldLog('warn')) console.warn(...args);
  }

  performance(startMark: string, endMark: string) {
    if (this.shouldLog('performance')) {
      try {
        performance.measure('temp', startMark, endMark);
        const measurements = performance.getEntriesByName('temp');
        if (measurements.length > 0) {
          console.log(`PERFORMANCE: ${startMark} -> ${endMark} - ${measurements[0].duration.toFixed(2)}ms`);
        }
      } catch (error) {
        console.log(`PERFORMANCE: ${startMark} -> ${endMark} - not available`);
      } finally {
        performance.clearMeasures('temp');
      }
    }
  }

  info(...args: any[]) {
    if (this.shouldLog('info')) console.info(...args);
  }

  debug(...args: any[]) {
    if (this.shouldLog('debug')) console.debug(...args);
  }

  log(...args: any[]) {
    if (this.shouldLog('debug')) console.log(...args);
  }
}

export const logger = new Logger();