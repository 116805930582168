import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import { logger } from "./Logger";

import './LandingPage.css';


const LandingPage: React.FC = () => {
  logger.log('Creating LandingPage');
  const navigate = useNavigate();

  const goToEditor = () => {
    navigate('/student'); 
  };

  const goToEditorTeacher = () => {
    const code = prompt("Please enter the teacher code:");
    if (code === '406816') {
      navigate('/teacher406816');
    } else if (code !== null) { // Check if user didn't click "Cancel"
      alert("Incorrect code. Access denied.");
    }
  };

  return (
    <div className="landing-page">
      <div className="page">
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-01.png`} alt="Kita Landing Page 1" />
        <button onClick={goToEditor} className="go-to-editor-btn student">
          Go to the Student Code Editor
        </button>
        <button onClick={goToEditorTeacher} className="go-to-editor-btn teacher">
          Go to the Teacher Code Editor
        </button>
      </div>
      <div className="page">
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-02.png`} alt="Kita Landing Page 2" />
      </div>
      <div className="page">
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-03.png`} alt="Kita Landing Page 3" />
      </div>
      <div className="page">
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-04.png`} alt="Kita Landing Page 4" />
      </div>
      <div className="page">
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-05.png`} alt="Kita Landing Page 5" />
      </div>
      <div className="page">
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-06.png`} alt="Kita Landing Page 6" />
      </div>
      <div className="page">
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-07.png`} alt="Kita Landing Page 7" />
      </div>
      <div className="page">
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-08.png`} alt="Kita Landing Page 8" />
      </div>
      <div className="page">
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-09.png`} alt="Kita Landing Page 9" />
      </div>
      <div className="page">
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-10.png`} alt="Kita Landing Page 10" />
      </div>
    </div>
  );
};

export default LandingPage;