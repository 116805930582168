import { useState, useRef, useEffect } from "react";
import { BsPencilFill, BsTrashFill } from "react-icons/bs";
import { FaEllipsisH, FaEllipsisV } from "react-icons/fa";
import { PiStudentFill } from "react-icons/pi";
import { logger } from "../Logger";

import './FileDropdown.css';

interface FileDropdownProps {
  isHorizontalDots: boolean;
  fileName: string;
  allowModifyFileName: boolean;
  allowDeleteFile: boolean;
  isClassOnly: boolean;
  allowChangeClassOnly: boolean;
  onRenameClick: (fileName: string) => void;
  onDeleteClick: (fileName: string) => void;
  onChangeClassOnly: (fileName: string, isClassOnly: boolean) => void;
};

// This is used in the FilesPane as well as the main pane and output pane tab section
const FileDropdown: React.FC<FileDropdownProps> = ({ 
  isHorizontalDots, fileName, allowModifyFileName, allowDeleteFile, isClassOnly, allowChangeClassOnly, 
  onRenameClick, onDeleteClick, onChangeClassOnly
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLDivElement>(null);


  const handleToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleRenameClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    logger.log('FileDropdown renameClick, fileName: ', fileName);
    e.stopPropagation();
    onRenameClick(fileName);
    setIsOpen(false);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    logger.log('FileDropdown deleteClick');
    e.stopPropagation();
    onDeleteClick(fileName);
    setIsOpen(false);
  };

  const handleChangeClassOnlyClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    logger.log('FileDropdown changeClassOnlyClick');
    e.stopPropagation();
    onChangeClassOnly(fileName, !isClassOnly);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if ((dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) && 
        (toggleRef.current && !toggleRef.current.contains(event.target as Node))) {
      setIsOpen(false);
    } else {
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && dropdownRef.current && toggleRef.current) {
      const toggleRect = toggleRef.current.getBoundingClientRect();
      dropdownRef.current.style.top = `${toggleRect.bottom}px`;
      dropdownRef.current.style.left = `${toggleRect.right - dropdownRef.current.offsetWidth}px`;
    }
  }, [isOpen]);

  return (
    <div className="file-menu">
      <div ref={toggleRef} onClick={handleToggle}>
        {isHorizontalDots ? 
          <FaEllipsisH /> : 
          <div className='tab-dropdown-button'>
            <FaEllipsisV />
          </div>
        }
      </div>
      {isOpen && (
        <div className="file-menu-dropdown" ref={dropdownRef}>
          {allowModifyFileName && (
            <div className="file-menu-item" onClick={handleRenameClick}>
              <BsPencilFill className="file-menu-icon" />
              Edit
            </div>
          )}
          {allowDeleteFile && (
            <div className="file-menu-item" onClick={handleDeleteClick}>
              <BsTrashFill className="file-menu-icon text-danger" />
              Delete
            </div>
          )}
          {allowChangeClassOnly && (
            <div className="file-menu-item" onClick={handleChangeClassOnlyClick}>
              <PiStudentFill className="file-menu-icon" />
              {isClassOnly ? 'Make C&S' : 'Make C'}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileDropdown;
